import React, { useEffect } from "react";
import styles from "./auth.module.css";
import * as Icons from "assets/icons.export";
import CSS from "csstype";
import { Form, Input, Button, Checkbox, message } from "antd";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";

import * as authActions from "data/reducers/auth.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "data/reducers";
import { constants } from "config";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { pca } from "index";
import {
  AuthenticationResult,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import ShellPage from "shared/nav-shell/ShellPage";

type loginFormState = {
  username: string;
  password: string;
};

export const LoginPage = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const history = useHistory();
  const dispatch = useDispatch();
  const { isLoggedIn, isLoading, isError, error, isAuthorised } = useSelector(
    (state: RootState) => state.authReducer
  );

  const { instance, accounts, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      dispatch(authActions.setStatusLoading({}))
      dispatch(authActions.getAuthToken({}));
    }
  }, []);

  const onSubmit = (data: loginFormState) => {
    dispatch(
      authActions.login({ emailId: data.username, password: data.password })
    );
  };

  useEffect(() => {
    if (accounts.length > 0) {
      dispatch(authActions.getAuthToken({}));
    }
  }, [accounts]);

  useEffect(() => {
    if (isLoggedIn && !isLoading) {
      history.push("/events");
      //history.push("/event")
      message.destroy();
      message.success("Logged in successfully!");
    } else if (!isLoggedIn && isLoading) {
      message.loading("Logging you in...");
    }
    if (isAuthorised) {
      history.push("/events")
    }
  }, [isLoggedIn, isLoading, isAuthorised]);

  useEffect(() => {
    if (isError && !isLoading) {
      message.destroy();
      message.error(error);
    }
  }, [isError, isLoading]);

  // unless the user is declared unAuthorized show the login page.
  return isAuthorised !== false ? (
    <div className={"min-h-screen w-full " + styles.background}>
      <div
        className={
          "min-h-screen w-full grid grid-flow-row grid-cols-2 " +
          styles.backgroundLayer
        }
      >
        <div className="text-center ml-auto my-auto grid grid-flow-row grid-cols-2 grid-rows-2 gap-4">
          <div className="border-r border-gray-500">
            <img
              src={Icons.Branding.AzureCommunitiesApacWhite}
              alt="Azure Communities Portal"
              className="pr-4"
            />
          </div>
          <div>
            <img
              src={Icons.Branding.MsftFullWhite}
              alt="Azure Communities Portal"
              className="h-12 mt-1"
            />
          </div>
          <div className="flex flex-col">
            <div className="text-gray-300 capitalize text-sm text-left">
              powered by
            </div>
            <img
              src={Icons.Branding.StyavaDev}
              alt="Azure Communities Portal"
              className="w-48 mt-1"
            />
          </div>
        </div>

        {/* Login form */}
        <div className="mr-auto my-auto w-full">
          <div className="text-yellow text-3xl font-semibold mb-4">Log in</div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col w-96"
          >
            <div className="flex flex-col mb-4">
              <div className="text-sm font-semiold text-white mb-1">Email</div>
              <input
                type="text"
                placeholder="name@domain.com"
                className="h-14 w-96 px-4 rounded-lg"
                {...register("username", {
                  required: "Email required",
                })}
              />
              <span className="text-red-500 text-xs">
                {errors.username?.message}
              </span>
            </div>
            <div className="flex flex-col mb-4">
              <div className="text-sm font-semiold text-white mb-1">
                Password
              </div>
              <input
                type="password"
                className="h-14 w-96 px-4 rounded-lg"
                placeholder="Password"
                {...register("password", {
                  required: "Password required",
                })}
              />
              <span className="text-red-500 text-xs">
                {errors.password?.message}
              </span>
            </div>
            <div className="grid grid-flow-row grid-cols-2">
              <div className="mb-4">
                <input
                  type="checkbox"
                  id="remember-me"
                  className="align-middle form-tick appearance-none h-5 w-5 border border-gray-300 rounded-md checked:bg-yellow"
                  {...register("rememberMe")}
                />
                <label
                  htmlFor="remember-me"
                  className="text-white pl-2 text-sm"
                >
                  Remember me
                </label>
              </div>
              <div className="text-gray-200 underline text-sm capitalize font-semibold text-right">
                forgot password?
              </div>
            </div>
            <button
              className="text-lg h-14 w-96 font-bold uppercase bg-yellow rounded-lg"
              type="submit"
            // onClick={() => history.push("/home")}
            >
              Log in
            </button>
          </form>

          <div className="text-white plb-4">
            Or Login via{" "}
            <button
              onClick={() => {
                instance.loginRedirect();
              }}
            >
              Microsoft
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <ShellPage showTabs={false}>
        <div className="w-full flex justify-center">
          <section className="w-5/6 text-lg text-center mlb-8 flex flex-col gap-8 items-center">
            You have successfully logged in, but you are not authorised to
            access this page. Please Logout and try again with different ID or
            contact administrator
            <Button type="primary" className="" onClick={() => {
              dispatch(authActions.removeO365Auth())
            }}>
              Logout
            </Button>
          </section>
        </div>
      </ShellPage>
    </>
  );
};
