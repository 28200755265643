import { Route, Switch, useRouteMatch } from "react-router-dom";
import CreateNewPost from "page-modules/post-module/CreateNewPost/CreateNewPost";
import ListPosts from "page-modules/post-module/ListPosts/ListPosts";

const PostRouter = () => {
  let { url, path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${url}/create`} exact>
        <CreateNewPost />
      </Route>
      <Route path={`${url}/:eventId`}></Route>
      <Route path={`${url}`} exact>
        <ListPosts />
      </Route>
    </Switch>
  );
};

export default PostRouter;
