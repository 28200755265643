import { constants } from "config";
import { removeEmpty } from "shared/utility/object_cleaner";
import { get, getHeaders, post } from "./services.common";

export const getTemplatesService = async () => {
  const url = constants.api + "Auth/GetAllTemplates";
  console.log("url "+url);
  const headers = getHeaders();
  return await get({ url, headers });
};
export const getTemplatesServiceAws = async () => {
  const url = constants.API + "/Auth/GetAllTemplates";
  console.log("url "+url);
  const headers = getHeaders();
  return await get({ url, headers });
};

export interface TemplateEmailParameter {
  eventId: string;
  templateSlug: string;
}

export const sendTemplateEmailsService = async (args: {
  urlParams: TemplateEmailParameter;
  body?: any;
}) => {
  const url = constants.api + "Auth/sendtemplatedmail";
  let params = new URLSearchParams(removeEmpty(args?.urlParams) as any);
  const headers = getHeaders();

  return await post({
    url: url + `?${params.toString()}`,
    headers,
    data: args?.body,
  });
};
export const sendTemplateEmailsServiceAws = async (args: {
  urlParams: TemplateEmailParameter;
  body?: any;
}) => {
  const url = constants.API + "/Auth/sendtemplatedmail";
  let params = new URLSearchParams(removeEmpty(args?.urlParams) as any);
  const headers = getHeaders();

  return await post({
    url: url + `?${params.toString()}`,
    headers,
    data: args?.body,
  });
};

export const getEmailLogs = async (args: {
  eventId: string;
  emailId: string;
  template: string;
}) => {
  const url = constants.api + "Auth/GetEmailLogs";
  let params = new URLSearchParams({
    emailid: args.emailId,
    eventid: args.eventId,
    template: args.template,
  });

  const headers = getHeaders();

  return await get({
    url: url,
    params: params
  })
};
