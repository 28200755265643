export type Event1 = {
  id: string;
  eventName: string;
  eventDescription: string;
  eventCover: EventCover[];
  eventDetails: string;
  eventDate: Date;
  eventStartTime: Date;
  eventEndTime: Date;
  popularSpeakers: PopularSpeaker[];
  eventOrganizers: null;
  parentCommunity: ParentCommunity[];
  parentHub: ParentHub[];
  rsvpLink: string;
  eventHTML: null;
  rsvpLinkType: string;
  rsvpFormIframe: null;
  isEventCompleted: null;
  isOnDemand: boolean;
  slug: string;
  count: number;
  webinarLink: string;
  eventHost: string;
};
export interface Event {
  id: string;
  length: number;

  flatData: FlatData;
}

export interface FlatData {
  id: string | null;
  eventName: string;
  eventTopic: string;
  eventDescription: string;
  eventCover: string | null;
  eventDetails: string;
  eventDate: string;
  eventStartTime: string;
  eventEndTime: string;
  //popularSpeakers: Speaker[];
  eventOrganizers: string | null;
  rsvpLink: string | null;
  eventHTML: string | null;
  rsvpLinkType: string | null;
  rsvpFormIframe: string | null;
  isEventCompleted: boolean | null;
  isOnDemand: boolean | null;
  slug: string;
  region: string;
  webinarLink: string | null;
  eventHost: string | null;
  count: number;
  eventType: string;
  eventYouTubeLink: string | null;
  eventCharges: string | null;
  length: number;
  postal_code: string;
  eventAgenda: any[];
  job_role: string;
  totalRegistrations:string;
  industry: string;
}

export type EventRegistrations = {
  event: Event;
  users: Users;
};

export type EventCover = {
  id: string;
  url: string;
  thumbnailUrl: string;
  mimeType: string;
};

export type PopularSpeaker = {
  id: string;
  userName: string;
  userDesignation: string;
  userOrganization: string;
  userCountry: null;
  isOrganizer: boolean;
  speakerInfo: null;
  userLinks: any[];
  userPhoto: EventCover[];
};

export type ParentHub = {
  id: string;
  hubName: string;
  hubDescription: string;
  hubFollowers: Number;
  serverID: string;
  memberCount: Number;
  slug: string;
};

export type ParentCommunity = {
  id: string;
  communityName: string;
  communityMembers: Number;
  communityHomePage: string;
  isFeatured: Boolean;
  slug: string;
  joinLink: {
    id: string;
    linkType: string;
    linkUrl: string;
  };
};

export type EventListFilter = {
  region: string;
  TextSearch: string;
  CommunityIDs: string[];
  HubIDs: string[];
  StartDate: string;
  EndDate: string;
  ShowDraft: Boolean;
  PageNo: Number;
  PageSize: Number;
};

export interface ParticipantModel {
  firstName: string;
  lastName: string;
  country: string;
  companyName: string;
  emailID: string;
}

export interface Users {
  pageNo: number;
  pageSize: number;
  totalCount: number;
  list: ParticipantModel[];
}

export interface RegistrationTable {
  key: number;
  srno: number;
  firstName: string;
  lastName: string;
  email: string;
  companyName: string;
  country: string;
}

export enum EventStatus {
  Upcoming,
  Ongoing,
  Ended,
}
