import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { constants } from "../../config";
// import { addAccessToken } from "../../data/reducers/auth.reducer"

import * as authActions from "data/reducers/auth.reducer";
import { pca } from "index";

const LoginCallback = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const accounts = pca.getAllAccounts();
    if (accounts.length > 0) {
      dispatch(authActions.setStatusLoading({}))
      dispatch(authActions.getAuthToken({}));
    }
  }, []);

  return <p>
    Redirecting you to the home page... Please wait...
  </p>;
};

export default LoginCallback;