import React, { useEffect } from "react";

import {  Link } from "react-router-dom"; 
import styles from "../events/event.module.css";
import * as Icons from "assets/icons.export";
import { Popover, Button, Table, Tabs, Select, Input, DatePicker } from "antd";
import { useHistory, useParams } from "react-router";
import * as Images from "assets/images.export";

import * as eventActions from "data/reducers/events.reducer";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "data/reducers";
import EventDetailsCard from "shared/components/events/EventDetailsCard";

import xlsx from "xlsx";
import { createExcelOfParticipants } from "shared/utility/excel_service";
import BroadCastEmail from "shared/components/emails/BroadcastEmail";
import EventDetailsCardDev from "shared/components/events/EventDetailsCardDev";
import BroadCastEmailAws from "shared/components/emails/BroadCastEmailAws";

const { TabPane } = Tabs;
const { Option } = Select;

export const EventDetailsPageDev = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = useParams<{ eventId: string }>();

  useEffect(() => {
    dispatch(eventActions.getEventRegisteration({
        eventSlug: params.eventId,
       
    }));
    return () => {};
  }, []);

  const { eventDetails, eventRegistrations } = useSelector(
    (state: RootState) => state.eventsReducer
  );

  // Registrations table data
  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
    },
    {
      title: "Email ID",
      dataIndex: "email",
    },
    {
      title: "Company",
      dataIndex: "companyName",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
  ];

  return (
    <div className="w-full min-h-screen">
      <div className={"w-full bg-white " + styles.topBarShadow}>
        <div className="w-5/6 mx-auto my-auto py-7">
          <div className="flex flex-row">
            <Button
              type="link"
              icon={
                <img
                  src={Icons.UI.BackArrow}
                  alt="back-arrow"
                  onClick={() => history.goBack()}
                />
              }
            />
            <div className="text-dark font-bold text-lg">
              {eventDetails?.flatData?.eventName}
            </div>
          </div>
        </div>
      </div>

      <div className="w-5/6 mx-auto">
        {/* Event card - horizontal */}
        <EventDetailsCardDev event={eventDetails} users={eventRegistrations} />

        {/* Tabs */}
        <div className="mt-10">
          <Tabs defaultActiveKey="1" onChange={() => {}} className="eventTabs">
            <TabPane tab="Registrations" key="1">
              <div className={styles.eventCardShadow}>
                <div className="w-full bg-white h-16 flex items-center justify-between text-cyan-primary">
                  <div>Total {eventRegistrations?.length} Registrations</div>
                  <button
                    onClick={() => {
                      createExcelOfParticipants(
                        eventRegistrations,
                        eventDetails?.id
                      );
                    }}
                    className="border-cyan-primary border-2 p-2 px-4 text-cyan-primary flex items-center gap-2"
                  >
                    <i className="material-icons">file_download</i>
                    Download List
                  </button>
                </div>
                <Table
                  columns={columns}
                  dataSource={eventRegistrations?.map((user, index: number) => {
                    return {
                      key: user?.emailID,
                      srno: index + 1,
                      firstName: user?.firstName,
                      lastName: user?.lastName,
                      email: user?.emailID,
                      companyName: user?.companyName,
                      country: user?.country,
                    };
                  })}
                  onChange={() => {}}
                />
              
              </div>
              <div className="mt-4">
        {/* Add a button or link to navigate to the Create Event page */}
        <Link to="/events/create" className="bg-cyan-primary text-white py-2 px-4 rounded-lg">
          Create New Event
        </Link>
      </div>
            </TabPane>
            <TabPane tab="Broadcast" key="2">
              <BroadCastEmailAws />
            </TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
};
