import axios from "axios";
import { constants } from "../../config";
import querystring from "querystring";

export const api = constants.api;

export const getHeaders = () => {
  return {
    "Access-Control-Allow-Origin": "*",
    // Authorization: `Bearer ${localStorage.getItem(constants.KEY_AUTH_TOKEN)}`,
    "X-Language": "en",
    "X-Flatten": true,
    "Accept-Language": "en"
  };
};

export const getLocalisationHeaders = () => {
  // TODO get locale from browser preferences
  return {
    "X-Languages": "en",
    "X-Flatten": true,
  };
};

export const getPaginationParams = ({ pagenumber = 0, pagesize = 20 }) => {
  return querystring.stringify({
    pagenumber: pagenumber + 1,
    pagesize: pagesize,
  });
};

export const stringifyParams = (params = {}) => {
  return querystring.stringify(params);
};

export const error = (e: any) => {
  return { status: "error", isSuccessful: false, message: e, data: null };
};

export const success = (data: any) => {
  return { status: "success", isSuccessful: true, data: data, message: null };
};

type GetRequestDetails = {
  url: string;
  headers?: any;
  params?: any;
};

export const get = async (
  requestDetails: GetRequestDetails = { url: "", params: {}, headers: {} }
) => {
  try {
    const stringParams = stringifyParams(`?${requestDetails.params}`);

    const response = await axios.get(`${requestDetails.url}${stringParams}`, {
      headers: requestDetails.headers,
    });
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

type PostRequestDetails = {
  url: string;
  params?: any;
  headers?: any;
  data?: any;
};

export const post = async (
  requestDetails: PostRequestDetails = {
    url: "",
    params: {},
    headers: {},
    data: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.post(
      `${requestDetails.url}${stringParams != "" ? "?" : ""}${stringParams}`,
      requestDetails.data,
      { headers: { ...requestDetails.headers } }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

type DownloadRequestDetails = {
  url: string;
  params?: any;
  data?: any;
  headers?: any;
};

export const post1 = async (requestDetails: PostRequestDetails) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const isFormData = requestDetails.data instanceof FormData;

    const headers = {
      ...requestDetails.headers,
      ...(isFormData ? {} : { 'Content-Type': 'application/json' }),
    };

    const response = await axios.post(
      `${requestDetails.url}${stringParams ? '?' + stringParams : ''}`,
      requestDetails.data,
      { headers }
    );
    return success(response.data);
  } catch (e) {
    console.error(e);
    return error(e);
  }
};

export const download = async (
  requestDetails: DownloadRequestDetails = {
    url: "",
    params: {},
    data: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.post(
      `${requestDetails.url}?${stringParams}`,
      requestDetails.data,
      {
        ...requestDetails.headers,
        responseType: "blob",
      }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

type UpdateRequestDetails = {
  url: string;
  params?: any;
  data?: any;
  headers?: any;
};

export const update = async (
  requestDetails: UpdateRequestDetails = {
    url: "",
    params: {},
    data: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.put(
      requestDetails.url + `?${stringParams}`,
      requestDetails.data,
      { headers: { ...requestDetails.headers } }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};

type DeleteRequestDetails = {
  url: string;
  params?: any;
  headers?: any;
};

export const del = async (
  requestDetails: DeleteRequestDetails = {
    url: "",
    params: {},
    headers: {},
  }
) => {
  try {
    const stringParams = stringifyParams(requestDetails.params);
    const response = await axios.delete(
      requestDetails.url + `?${stringParams}`,
      { ...requestDetails.headers }
    );
    return success(response.data);
  } catch (e) {
    console.log(e);
    return error(e);
  }
};
