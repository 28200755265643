import { Button } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import styles from "page-modules/communities/community.module.css";
import { EventListFilter } from "data/models/event.model";
import { Input, Select, DatePicker, Pagination } from "antd";
import { getToAndFromDates } from "shared/utility/date";
import { useDispatch, useSelector } from "react-redux";

import * as communityActions from "data/reducers/community.reducer";
import * as hubActions from "data/reducers/hub.reducer";
import { RootState } from "data/reducers";
import CommunityCard from "shared/components/community/CommunityCard";

const { Search } = Input;
const { Option } = Select;

export default function CommunityListPage() {
  const history = useHistory();

  const dispatch = useDispatch();

  const { communitySearchList, totalCount } = useSelector(
    (root: RootState) => root.communityReducer
  );

  const { hubsSearchList } = useSelector((root: RootState) => root.hubsReducer);

  const [filterData, setFilterData] = useState({
    TextSearch: "",
    CommunityIDs: [],
    HubIDs: "",
    StartDate: "",
    EndDate: "",
    ShowDraft: false,
    SortBy: "",
    Tags: "",
    PageNo: 1,
    PageSize: 20,
    region: "",
  });

  useEffect(() => {
    dispatch(communityActions.getCommunities(filterData));
  }, []);

  useEffect(() => {
    dispatch(communityActions.getCommunities(filterData));
  }, [filterData]);

  return (
    <div className="w-full min-h-screen">
      <div className={"w-full bg-white " + styles.topBarShadow}>
        <div className="w-5/6 mx-auto my-auto py-7">
          <div className="w-80 inline-block align-top mr-7">
            <Search
              placeholder="Search Communities"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(data: string) => {
                setFilterData({ ...filterData, TextSearch: data });
              }}
            />
          </div>

          {/* Hubs */}
          <div className="mr-7 inline-block">
            <Select
              showSearch
              size="large"
              mode="tags"
              placeholder="Hubs"
              maxTagCount="responsive"
              allowClear
              style={{ width: 120 }}
              onChange={(value, option) => {
                const selectedHubs: string[] = [];
                option?.forEach((element: any) => {
                  selectedHubs.push(element?.key);
                });
                setFilterData({
                  ...filterData,
                  HubIDs: selectedHubs.join(","),
                });
              }}
              onSearch={(value) => {
                dispatch(
                  hubActions.getHubs({
                    PageSize: 5,
                    PageNo: 1,
                    region: undefined,
                    TextSearch: value,
                  })
                );
              }}
            >
              {hubsSearchList.map((hub: any) => {
                return (
                  <Option value={hub?.slug} key={hub?.id}>
                    {hub?.hubName}
                  </Option>
                );
              })}
            </Select>
          </div>

          {/* Action buttons */}
          <div className="float-right">
            <Button
              type="primary"
              ghost
              size="large"
              className="w-40 mr-7 uppercase"
              style={{ fontSize: "0.875rem" }}
            >
              Drafts
            </Button>
            <Button
              type="primary"
              size="large"
              className="uppercase bg-black"
              style={{ fontSize: "0.875rem" }}
              onClick={() => history.push("/events/create")}
            >
              Create New Community
            </Button>
          </div>
        </div>
      </div>

      <div className="w-5/6 mx-auto my-8 flex flex-wrap gap-8 justify-center">
        {Object.entries(communitySearchList).map(([slug, communtiy]) => {
          return <CommunityCard community={communtiy} />;
        })}
      </div>

      <div className="w-full md:w-5/6 flex justify-end my-8">
        <Pagination
          onChange={(pageNumber: number, pageSize: number) => {
            setFilterData({
              ...filterData,
              PageSize: pageSize,
              PageNo: pageNumber,
            });
          }}
          total={totalCount}
          pageSize={filterData?.PageSize}
        />
      </div>
    </div>
  );
}
