import { PostObject } from "data/models/post.model";
import React from "react";
import styles from "shared/components/components.module.scss";
import { notNullUndef } from "shared/utility/null_check";

export default function PostCard(props: {
  post: PostObject;
  showImage?: boolean;
}) {
  return (
    <div className={`bg-white ${styles.postCardShadow} p-5 flex gap-4`}>
      {props?.showImage && props?.post?.postImages.length > 0 && (
        <div className="w-1/3">
          <img
            className="h-full object-cover"
            src={props.post?.postImages[0]?.url}
            alt=""
          />
        </div>
      )}
      <div className="flex flex-col h-full flex-wrap justify-between">
        <h3 className="text-base font-semibold font-body text-black">
          {props.post?.postTitle}
        </h3>

        <section className="text-sm flex flex-col gap-2">
          {notNullUndef(props?.post?.parentAuthor) && (
            <div>
              Name -{" "}
              <span className="font-bold">
                {props.post?.parentAuthor?.length > 1
                  ? "Multiple Authors"
                  : `${props.post?.parentAuthor[0]?.userName}`}
              </span>
            </div>
          )}
          {notNullUndef(props?.post?.parentAuthor) && (
            <div>
              Designation -
              <span>
                {props.post?.parentAuthor?.length > 1
                  ? "Multiple Authors"
                  : `${props.post?.parentAuthor[0]?.userDesignation}`}
              </span>
            </div>
          )}
          <div className="text-blue-400 flex items-center justify-start">
            <i className="material-icons text-md">event</i> -{" "}
            {new Date(props?.post?.postDate).toLocaleDateString()}
          </div>
        </section>
      </div>
    </div>
  );
}
