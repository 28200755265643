import React from "react";

export const NotFound = () => {
  return (
    <div className="min-h-screen">
      <div className="mt-48 text-center">
        <h1 className="text-4xl leading-6 font-bold text-gray-300">404 :/</h1>
        <h2 className="text-3xl leading-6 font-bold text-gray-300">
          Page Not Found
        </h2>
      </div>
    </div>
  );
};

export default NotFound;
