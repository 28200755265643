import {
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Form,
  Carousel,
  Upload,
  Modal,
} from "antd";
import React, { useState } from "react";
import styles from "./event.module.css";
import * as Icons from "assets/icons.export";
import { useHistory } from "react-router";
import { UploadFile } from "antd/lib/upload/interface";
import PlusOutlined from "@ant-design/icons/lib/icons/PlusOutlined";

const { TextArea, Search } = Input;
const { Option } = Select;

// type newFileList = { uid: string; name: string; status: string; url: string }[];

const CreateEventPage = () => {
  const history = useHistory();
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isSpeakerModalVisible, setIsSpeakerModalVisible] = useState(false);
  const [isOrganizerModalVisible, setIsOrganizerModalVisible] = useState(false);

  const showSpeakerModal = () => {
    setIsSpeakerModalVisible(true);
  };

  const showOrganizerModal = () => {
    setIsOrganizerModalVisible(true);
  };

  const handleOk = () => {
    setIsSpeakerModalVisible(false);
    setIsOrganizerModalVisible(false);
  };

  const handleCancel = () => {
    setIsSpeakerModalVisible(false);
    setIsOrganizerModalVisible(false);
  };

  const onChange = ({ fileList: newFileList }: any) => {
    setFileList(newFileList);
  };

  const onPreview = async (file: any) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  return (
    <div className="w-full min-h-screen">
      <div className={"w-full bg-white " + styles.topBarShadow}>
        <div className="w-5/6 mx-auto my-auto py-7">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row my-auto">
              <Button
                type="link"
                icon={
                  <img
                    src={Icons.UI.BackArrow}
                    alt="back-arrow"
                    onClick={() => history.goBack()}
                  />
                }
              />
              <div className="text-dark font-bold text-lg">
                Create New Event
              </div>
            </div>
            {/* Action buttons */}
            <div className="flex">
              <Button
                type="primary"
                ghost
                size="large"
                className="w-40 mr-7 uppercase"
                style={{ fontSize: "0.875rem" }}
              >
                Save changes
              </Button>
              <Button
                type="primary"
                size="large"
                className="uppercase bg-black"
                style={{ fontSize: "0.875rem" }}
                onClick={() => history.push("/events/create")}
              >
                Post event
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:w-5/6 w-full mx-auto py-8">
        {/* Basic Details */}
        <div className={"bg-white p-5 w-full " + styles.tableShadow}>
          {/* Title */}
          <div className="text-blue-primary font-bold text-lg">
            Basic Details
          </div>
          <div className="mt-6">
            {/* Event title */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Event Title</div>
              <Input placeholder="Event Title" size="large" />
            </div>
            {/* Event description */}
            <div className="flex flex-col">
              <div className="text-sm font-bold mb-2">Event Description</div>
              <TextArea
                placeholder="Event Description..."
                autoSize={{ minRows: 8, maxRows: 10 }}
              />
            </div>
          </div>
        </div>

        {/* Event Timings */}
        <div className={"bg-white p-5 w-full my-8 " + styles.tableShadow}>
          {/* Title */}
          <div className="text-blue-primary font-bold text-lg">
            Event Timings
          </div>
          <div className="mt-6 grid grid-flow-row grid-cols-5 gap-5">
            {/* Timezone */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Time Zone</div>
              <Select
                defaultValue="lucy"
                placeholder="Timezone"
                size="large"
                onChange={() => {}}
              >
                <Option value="jack">Jack</Option>
                <Option value="Yiminghe">yiminghe</Option>
              </Select>
            </div>

            {/* Start Date/Time */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Start Date</div>
              <DatePicker size="large" onChange={() => {}} />
            </div>
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Start Time</div>
              <TimePicker size="large" onChange={() => {}} />
            </div>

            {/* Start Date/Time */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">End Date</div>
              <DatePicker size="large" onChange={() => {}} />
            </div>
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">End Time</div>
              <TimePicker size="large" onChange={() => {}} />
            </div>
          </div>
        </div>

        {/* Event Details */}
        <div className={"bg-white p-5 w-full my-8 " + styles.tableShadow}>
          {/* Title */}
          <div className="text-blue-primary font-bold text-lg">
            Event Details
          </div>
          <div className="mt-6">
            <div className="grid grid-flow-row grid-cols-2 gap-5 border-b">
              {/* Event Images */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">Cover Image</div>
                <Carousel afterChange={() => {}} autoplay={true}>
                  <div>
                    <h3 className="h-72 w-full bg-dark" />
                  </div>
                  <div>
                    <h3 className="h-72 w-full bg-dark" />
                  </div>
                </Carousel>
                <div className="flex flex-row">
                  <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    onPreview={onPreview}
                  >
                    {fileList.length < 5 && "+ Upload"}
                  </Upload>
                </div>
              </div>

              {/* Hubs/Communities */}
              <div className="w-full flex flex-col">
                {/* Event Hubs */}
                <div className="flex flex-col mb-7">
                  <div className="text-sm font-bold mb-2">Hubs</div>
                  <Select
                    showSearch
                    mode="multiple"
                    size="large"
                    className="w-full"
                    placeholder="Add Hubs"
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="ryan">Ryan</Option>
                    <Option value="jerry">Jerry</Option>
                  </Select>
                </div>
                {/* Event Communities */}
                <div className="flex flex-col mb-7">
                  <div className="text-sm font-bold mb-2">Communities</div>
                  <Select
                    showSearch
                    mode="multiple"
                    size="large"
                    className="w-full"
                    placeholder="Add Hubs"
                    onChange={() => {}}
                  >
                    <Option value="jack">Jack</Option>
                    <Option value="ryan">Ryan</Option>
                    <Option value="jerry">Jerry</Option>
                  </Select>
                </div>
              </div>
            </div>
            <div className="grid grid-flow-row grid-cols-2 gap-5 mt-7">
              {/* RSVP Link Type */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">RSVP Link Type</div>
                <Select defaultValue="lucy" size="large">
                  <Option value="lucy">Lucy</Option>
                </Select>
              </div>
              {/* Slug */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">Slug</div>
                <Input placeholder="Slug" size="large" />
              </div>
            </div>
          </div>
        </div>

        {/* Event Speakers */}
        <div className={"bg-white p-5 w-full my-8 " + styles.tableShadow}>
          <div className="text-blue-primary font-bold text-lg">
            Event Speakers
          </div>
          <div className="grid grid-flow-row grid-cols-3 gap-5 mt-6">
            {/* Speaker Card */}
            <div className="h-36 my-auto w-full bg-gray-100"></div>
            {/* Add Speaker Button */}
            <button
              className="bg-gray-100 cursor-pointer border p-6 hover:border-blue-primary hover:border-opacity-50 hover:bg-blue-primary hover:bg-opacity-5"
              onClick={showSpeakerModal}
            >
              <div className="rounded-full h-24 w-24 border border-dashed border-black mx-auto bg-white">
                <img
                  src={Icons.UI.AddPerson}
                  alt="add-text"
                  className="mx-auto my-6"
                />
              </div>
            </button>
          </div>
          <Modal
            title="Speaker List"
            visible={isSpeakerModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Search placeholder="Search Speakers" size="large" />
            <Button type="primary" size="large" className="w-full my-5">
              <PlusOutlined style={{ verticalAlign: "text-top" }} />
              Create New Speaker
            </Button>
          </Modal>
        </div>

        {/* Agenda Details */}
        <div className={"bg-white p-5 w-full my-8 " + styles.tableShadow}>
          <div className="text-blue-primary font-bold text-lg">
            Agenda Details
          </div>
          {/* Agenda */}
          <div className="border-b">
            <div className="mt-6 text-base font-bold text-cyan-primary">
              Agenda 1
            </div>
            <div className="grid grid-flow-row grid-cols-3 gap-5 mt-4">
              {/* Speaker Name */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">Speaker Name</div>
                <Input placeholder="Speaker Name" size="large" />
              </div>
              {/* Agenda */}
              <div className="flex flex-col mb-7 col-span-2">
                <div className="text-sm font-bold mb-2">Agenda</div>
                <Input placeholder="Agenda" size="large" />
              </div>
            </div>

            <div className="flex flex-row gap-10">
              {/* Agenda Start */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">Agenda Start</div>
                <div className="flex flex-row gap-5">
                  <DatePicker size="large" />
                  <TimePicker size="large" />
                </div>
              </div>
              {/* Agenda End */}
              <div className="flex flex-col mb-7">
                <div className="text-sm font-bold mb-2">Agenda End</div>
                <div className="flex flex-row gap-5">
                  <DatePicker size="large" />
                  <TimePicker size="large" />
                </div>
              </div>
            </div>
          </div>

          {/* Add New Agenda */}
          <Button
            type="primary"
            size="large"
            className="uppercase bg-black mt-7"
            style={{ fontSize: "0.875rem" }}
            onClick={() => history.push("/events/create")}
          >
            <PlusOutlined style={{ verticalAlign: "text-top" }} />
            Add Agenda
          </Button>
        </div>

        {/* Event Organizers */}
        <div className={"bg-white p-5 w-full mt-8 " + styles.tableShadow}>
          <div className="text-blue-primary font-bold text-lg">
            Event Organizers
          </div>
          <div className="grid grid-flow-row grid-cols-3 gap-5 py-7 border-b">
            {/* Speaker Card */}
            <div className="h-36 my-auto w-full bg-gray-100"></div>
            {/* Add Speaker Button */}
            <button
              className="bg-gray-100 cursor-pointer border p-6 hover:border-blue-primary hover:border-opacity-50 hover:bg-blue-primary hover:bg-opacity-5"
              onClick={showOrganizerModal}
            >
              <div className="rounded-full h-24 w-24 border border-dashed border-black mx-auto bg-white">
                <img
                  src={Icons.UI.AddPerson}
                  alt="add-text"
                  className="mx-auto my-6"
                />
              </div>
            </button>
          </div>
          <div className="grid grid-flow-row grid-cols-2 gap-5 mt-7">
            {/* Host */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Host</div>
              <Input placeholder="Host" size="large" />
            </div>
            {/* Event Link */}
            <div className="flex flex-col mb-7">
              <div className="text-sm font-bold mb-2">Event Link</div>
              <Input placeholder="Event Link" size="large" />
            </div>
          </div>
          <Modal
            title="Organizer List"
            visible={isOrganizerModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Search placeholder="Search Organizers" size="large" />
            <Button type="primary" size="large" className="w-full my-5">
              <PlusOutlined style={{ verticalAlign: "text-top" }} />
              Create New Organizer
            </Button>
          </Modal>
        </div>
      </div>

      {/* Form action butons */}
      <div className="flex flex-row justify-center gap-5 pb-8">
        <button className="px-10 py-4 uppercase border border-blue-primary bg-blue-primary hover:text-blue-primary hover:bg-transparent text-white font-bold text-sm transition-all">
          Save Changes
        </button>
        <button className="px-10 py-4 uppercase border border-green-primary bg-green-primary hover:text-green-primary hover:bg-opacity-10 text-white font-bold text-sm transition-all">
          Post Event
        </button>
      </div>
    </div>
  );
};

export default CreateEventPage;
