import { Community } from "data/models/community.model";
import React from "react";
import ReactMarkdown from "react-markdown";

import styles from "shared/components/components.module.scss";
import * as Icons from "assets/icons.export";
import { Link } from "react-router-dom";

export default function CommunityCard(props: { community: Community }) {
  return (
    <div className={`${styles.communityCard} flex flex-col justify-between`}>
      <div>
        <div className="flex items-center gap-4 my-2 mbs-8 mx-6">
          <div>
            <img
              src={Icons.Placeholders.CommunityPlaceholderIcon}
              alt="Community Icon"
              className="h-8"
            />
          </div>
          <div>
            <h2 className="open-sans my-0 font-semibold">{props.community?.communityName}</h2>
            <p className={`${styles.hubName} my-0`}>
              {props.community?.parentHub &&
              props.community?.parentHub.length > 0 ? (
                props?.community?.parentHub[0]?.hubName
              ) : (
                <></>
              )}
            </p>
          </div>
        </div>

        <div
          className={`${styles.descriptionClip} mx-6`}
          dangerouslySetInnerHTML={{
            __html: props.community?.communityDescription,
          }}
        ></div>
      </div>

      <div className={`${styles.bottomBar} mt-2 flex items-center justify-between px-8`}>
        <div className="flex items-center">
          <img src={Icons.UI.GroupMembers} alt="" />
          <span className="text-primary-blue mli-4">
            {props.community?.communityMembers} Members
          </span>
        </div>

        <Link to="" className={`${styles.viewOrange}`}>View</Link>
      </div>
    </div>
  );
}
