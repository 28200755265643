import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export enum ApplicationTabs {
  Home,
  Events,
  Posts,
  Communities,
  allblogs
}

const ShellSlice = createSlice({
  name: "shellSlice",
  initialState: {
    selectedTab: ApplicationTabs.Home,
  },
  reducers: {
    changeSelectedTab: (state, action) => {
      state.selectedTab = action.payload.selectedTab;
    },
  },
  extraReducers: {},
});

export const { changeSelectedTab } = ShellSlice.actions;
export default ShellSlice.reducer;
