import AllBlogsPage from "page-modules/blogs/AllBlogsPage";
import CommunityListPage from "page-modules/communities/CommunityListPage";
import CreateCommunityPage from "page-modules/communities/CreateCommunityPage";
import * as React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

const BlogsRouter = () => {
    let { url, path } = useRouteMatch();

    return (
        <Switch>
            
            <Route path={`${url}`} exact>
                <AllBlogsPage />
            </Route>
        </Switch>
    );
};

export default BlogsRouter;
