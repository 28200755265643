import { LoginPage } from "page-modules/auth/LoginPage";
import NotFound from "page-modules/error/NotFound";
import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import ShellPage from "shared/nav-shell/ShellPage";
import AuthRouter from "./auth.router";
import DashboardRouter from "./dashboard.router";

const PrimaryRouter = () => {
  return (
    <Switch>
      {/* Unauthenticated Routes */}
      <Route path="/" exact>
        <Redirect to="/auth" />
      </Route>
      <Route path="/auth">
        <AuthRouter />
      </Route>
      {/* Authenticated Routes */}
      <ShellPage>
        <DashboardRouter />
      </ShellPage>

      {/* 404 Not found */}
      <Route path="*" exact>
        <NotFound />
      </Route>
    </Switch>
  );
};

export default PrimaryRouter;
