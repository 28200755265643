import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  Event,
  EventListFilter,
  EventRegistrations,
  ParticipantModel,
} from "data/models/event.model";
import { EventData } from "data/models/eventNew.model";
import { EventFlatData, EventNew } from "data/models/eventNewDetail.model";
import { PagingInfo } from "data/models/post.model";
import {
  getEventService,
  getEventServiceRegistered,
  getEventsService,
  getEventsServiceaws,
} from "data/services/events.service";

export const getEvents = createAsyncThunk(
  "events/getEvents",
  async (payload: EventListFilter, thunkApi) => {
    const response = await getEventsService({ ...payload });
    
  
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue(response.message);
    }
  }
);
export const getEventsaws = createAsyncThunk(
  "events/getEventsaws",
  async (payload: EventListFilter, thunkApi) => {
    const response = await getEventsServiceaws({ ...payload });
    
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue(response.message);
    }
  }
);

export const getEvent = createAsyncThunk(
  "events/getEvent",
  async (payload: { eventSlug: string; pageNumber?: number }, thunkApi) => {
    const response = await getEventService({ eventSlug: payload.eventSlug });
    if (response.isSuccessful) {
      const responseJson = response.data;
      const eventRegistrations: EventRegistrations = responseJson?.data;
      const tripsRequired = Math.ceil(
        eventRegistrations?.users?.totalCount /
          eventRegistrations?.users?.pageSize
      );

      for (let i = 2; i <= tripsRequired; i++) {
        thunkApi.dispatch(
          getAdditionalParticipants({
            eventSlug: payload?.eventSlug,
            pageNo: i,
          })
        );
      }

      return responseJson;
    } else {
      return thunkApi.rejectWithValue({
        name: "events/getEvent",
        event: payload.eventSlug,
      });
    }
  }
);
export const getEventRegisteration = createAsyncThunk(
  "events/getEventRegistration",
  async (payload: { eventSlug: string; pagesize?: number }, thunkApi) => {
    const response = await getEventServiceRegistered({ eventSlug: payload.eventSlug });
    console.log("hi i am in event registration reducer")
    if (response.isSuccessful) {
      const responseJson = response.data;
      const eventRegistrations: EventRegistrations = responseJson?.data;
      const tripsRequired = Math.ceil(
        eventRegistrations?.users?.totalCount /
          eventRegistrations?.users?.pageSize
      );

      for (let i = 2; i <= tripsRequired; i++) {
        thunkApi.dispatch(
          getAdditionalParticipant({
            eventSlug: payload?.eventSlug,
          
            pageNo: i,
          })
        );
      }

      return responseJson;
    } else {
      return thunkApi.rejectWithValue({
        name: "events/getEventRegistration",
        event: payload.eventSlug,
      });
    }
  }
);
export const getAdditionalParticipant = createAsyncThunk(
  "events/additionalParticipant",
  async (payload: { eventSlug: string; pageNo: number }, thunkApi) => {
    const response = await getEventServiceRegistered({
      eventSlug: payload.eventSlug,
     
    });
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue({
        name: "events/getEvent",
        event: payload.eventSlug,
      });
    }
  }
);
export const getAdditionalParticipants = createAsyncThunk(
  "events/additionalParticipants",
  async (payload: { eventSlug: string; pageNo: number }, thunkApi) => {
    const response = await getEventService({
      eventSlug: payload.eventSlug,
      pageNumber: payload.pageNo,
    });
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue({
        name: "events/getEvent",
        event: payload.eventSlug,
      });
    }
  }
);

const eventSlice = createSlice({
  name: "eventSlice",
  initialState: {
    isLoading: false,
    isError: false,
    error: "",
    eventList: <Event[]>[],
    event4:  <EventData[]>[],
    eventDetails: <Event>{},
    eventRegistrations: <ParticipantModel[]>[],
    pagination: {} as PagingInfo,
    eventListPortal: <Event[]>[]
  },
  reducers: {},
  extraReducers: {
    [getEventsaws.pending.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      message.destroy();
      message.loading("Fetching data");
    },
    [getEventsaws.fulfilled.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      state.eventListPortal = action?.payload?.data;
      state.pagination = action?.payload?.pagingInfo;

      message.destroy();
      message.success("Data fetch successfully");
    },
    [getEventsaws.rejected.type]: (state, action) => {
      state.isLoading = true;
      state.error = action.error.message;
      state.isError = false;
      message.destroy();
      message.error(action.error.message);
    },
    [getEvents.pending.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      message.destroy();
      message.loading("Fetching data");
    },
    [getEvents.fulfilled.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      state.eventList = action?.payload?.data;
      state.event4=action?.payload?.data;
      state.pagination = action?.payload?.pagingInfo;

      message.destroy();
      message.success("Data fetch successful");
    },
    [getEvents.rejected.type]: (state, action) => {
      state.isLoading = true;
      state.error = action.error.message;
      state.isError = false;
      message.destroy();
      message.error(action.error.message);
    },
    [getEvent.fulfilled.type]: (
      state,
      action: { payload: { data: EventRegistrations } }
    ) => {
      const data = action.payload.data;
      state.eventDetails = data?.event;
      state.eventRegistrations = data?.users?.list;
    },
    [ getEventRegisteration.fulfilled.type]: (
      state,
      action: { payload: { data: EventRegistrations } }
    ) => {
      const data = action.payload.data;
      state.eventDetails = data?.event;
      state.eventRegistrations = data?.users?.list;
    },
    [getAdditionalParticipants.fulfilled.type]: (
      state,
      action: { payload: { data: EventRegistrations } }
    ) => {
      const data = action.payload.data;
      state.eventRegistrations = [
        ...state.eventRegistrations,
        ...data?.users?.list,
      ];
    },
  },
});

export default eventSlice.reducer;
