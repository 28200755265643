import React from 'react'
import { BlogsListPage } from './BlogsList'

const AllBlogsPage = () => {
  return (
    <div className=''>
      <BlogsListPage/>
    </div>
  )
}

export default AllBlogsPage
