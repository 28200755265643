import { Popover } from "antd";
import React from "react";
import styles from "page-modules/events/event.module.css";

import * as Images from "assets/images.export";
import * as Icons from "assets/icons.export";
import {
  Event,
  EventRegistrations,
  ParticipantModel,
  Users,
} from "data/models/event.model";
import { constants } from "config";
import { notNullUndef } from "shared/utility/null_check";

export default function EventDetailsCardDev(props: {
  event: Event;
  users: ParticipantModel[];
}) {
  return (
    <div className={"w-full bg-white p-4 " + styles.eventCardShadow}>
      <div className="w-full flex flex-row">
        <div className="h-44 w-auto object-cover relative">
         
            <img
              src={Images?.placeholders?.EventCardPlaceholder}
              alt="event-image"
              className="h-44 object-cover"
            />
       

          <div className="text-xs px-4 py-2 flex flex-row absolute bottom-0 gap-4 divide-x-2 font-semibold text-white bg-blue-tertiary w-full">
            <div className="">
              Event Status: <span className={styles.ongoing}>Ongoing</span>
            </div>
            <div className="pl-4">
              No. of Registrations:{" "}
              <span className="font-bold">{props?.users?.length}</span>
            </div>
          </div>
        </div>
        <div className="px-4">
          {/* Title */}
          <div className="text-base font-semibold mb-4">
            {props?.event?.flatData?.eventName}
          </div>
          {/* Speakers */}
          <div className="text-sm text-gray-500 mb-2">
            Speaker:{" "}
            <span className="font-semibold capitalize">Multiple Speakers</span>
          </div>
          {/* Hubs */}
          {/* <div className="flex flex-row mb-2">
            <img
              src={Icons.Placeholders.HubsPlaceholderIcon}
              className="h-5 w-5"
              alt="hub-logo"
            />
            {props?.event?.parentHub?.length > 1 ? (
              <Popover
                content={
                  <ul>
                    {props?.event?.parentHub?.map((hub) => {
                      return <li>{hub?.hubName}</li>;
                    })}
                  </ul>
                }
                title="Hubs"
              >
                <span className="text-sm hover:font-bold text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
                  Multiple Hubs
                </span>
              </Popover>
            ) : (
              <div>
                {props?.event?.parentHub?.length == 1 ? (
                  <span>{props?.event?.parentHub[0]?.hubName}</span>
                ) : (
                  <></>
                )}
              </div>
            )}
          </div>
          {/* Communities */}
          <div className="flex flex-row mb-3">
            <img
              src={Icons.Placeholders.CommunityPlaceholderIcon}
              className="h-5 w-5"
              alt="community-logo"
            />

            {/* {props?.event?.parentCommunity?.length > 1 ? (
              <Popover
                content={ */}
            {/* //       <ul> */}
            {/* //         {props?.event?.parentCommunity?.map((community) => { */}
            {/* //           return <li>{community.communityName}</li>;
            //         })}
            //       </ul>
            //     }
            //     title="Communities"
            //   > */}
            {/* //     <span className="text-sm hover:font-bold text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
            //       Multiple Communities
            //     </span>
            //   </Popover> */}
            {/* // ) : (
          //     <div>
          //       {props?.event?.parentCommunity?.length == 1 ? ( */}
        
            <div className="">
              <img
                src={Icons.UI.Calendar}
                className="h-5 w-5 inline"
                alt="event-date"
              />
              <span className="text-sm font-semibold text-gray-500 ml-1">
                {new Date(props?.event?.flatData?.eventDate).toDateString()}
              </span>
            </div>
            {/* Time */}
            <div className="pl-2">
              <img
                src={Icons.UI.Clock}
                className="h-5 w-5 inline"
                alt="event-time"
              />
              <span className="text-sm font-semibold text-gray-500 ml-1">
                {new Date(props?.event?.flatData?.eventStartTime).toTimeString()} -{" "}
                {new Date(props?.event?.flatData?.eventEndTime).toTimeString()}
              </span>
            </div>
          </div>
        </div>
        <div className="px-4 pl-14 ml-auto border-l">
          <div className="flex flex-col gap-5 my-7">
            <button className="h-12 w-40 uppercase border rounded-none border-blue-primary font-bold text-white bg-blue-primary hover:bg-blue-secondary transition-all">
              Edit
            </button>
            <button className="h-12 w-40 uppercase border rounded-none border-blue-primary font-bold text-blue-primary hover:bg-blue-primary hover:text-white transition-all">
              View
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
