import { ParticipantModel, Users } from "data/models/event.model";
import XLSX from "xlsx";

const createExcelOfParticipants = (
  dataList: ParticipantModel[],
  filename: string
) => {
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(dataList);
  XLSX.utils.book_append_sheet(workbook, worksheet, "Participants");
  XLSX.writeFile(workbook, `${filename}.xlsx`);
};

export { createExcelOfParticipants };
