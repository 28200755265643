import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import {
  getTemplatesService,
  getTemplatesServiceAws,
  sendTemplateEmailsService,
  sendTemplateEmailsServiceAws
} from "data/services/email.service";

import { EmailTemplate } from "data/models/email.model";

export const getAllTemplates = createAsyncThunk(
  "emailReducer/getTemplates",
  async (payload, thunkApi) => {
    try {
      const response = await getTemplatesService();
      if (response.isSuccessful) {
        return response.data;
      }
    } catch (err) {
      return thunkApi.rejectWithValue({
        name: "emailReducer/getTemplates",
        payload,
      });
    }
  }
);
export const getAllTemplateAws = createAsyncThunk(
  "emailReducer/getTemplateAws",
  async (payload, thunkApi) => {
    try {
      const response = await getTemplatesServiceAws();
      if (response.isSuccessful) {
        return response.data;
      }
    } catch (err) {
      return thunkApi.rejectWithValue({
        name: "emailReducer/getTemplateAws",
        payload,
      });
    }
  }
);

export const sendTemplateEmail = createAsyncThunk(
  "emailReducer/sendTemplateEmail",
  async (
    payload: {
      urlParams: { eventId: string; templateSlug: string };
      body: any;
    },
    thunkApi
  ) => {
    try {
      const response = await sendTemplateEmailsService(payload);
      if (response.isSuccessful) {
        return response.data;
      }
    } catch (error) {
      return thunkApi.rejectWithValue({
        name: "emailReducer/sendTemplateEmail",
        payload,
      });
    }
  }
);

export const sendTemplateEmailAws = createAsyncThunk(
  "emailReducer/sendTemplateEmailAws",
  async (
    payload: {
      urlParams: { eventId: string; templateSlug: string };
      body: any;
    },
    thunkApi
  ) => {
    try {
      const response = await sendTemplateEmailsServiceAws(payload);
      if (response.isSuccessful) {
        return response.data;
      }
    } catch (error) {
      return thunkApi.rejectWithValue({
        name: "emailReducer/sendTemplateEmailAws",
        payload,
      });
    }
  }
);
const emailSlice = createSlice({
  name: "emailSlice",
  initialState: {
    isLoading: false,
    isError: false,
    error: "",
    templates: {} as { [key: string]: EmailTemplate },
  },
  reducers: {},
  extraReducers: {
    [sendTemplateEmailAws.pending.type]: (state, action) => {
      state.isLoading = true;
      state.error = "";
      state.isError = false;
      message.destroy();
      message.loading("Email Sending wait..");
    },
    [getAllTemplates.fulfilled.type]: (state, action) => {
      const data = action?.payload?.data as EmailTemplate[];
      data.forEach((template) => {
        state.templates[template?.slug] = template;
        
      });
    },
   
      [getAllTemplateAws.fulfilled.type]: (state, action) => {
        const data = action?.payload?.data as EmailTemplate[];
        if (Array.isArray(data)) {
          data.forEach((template) => {
            state.templates[template?.slug] = template;
          });
        }
      }
    
  },
});

export default emailSlice.reducer;
