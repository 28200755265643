import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Hub, HubListFilter } from "data/models/hub.model";
import { getHubsService } from "data/services/hub.service";

export const getHubs = createAsyncThunk(
  "hubs/getHubs",
  async (payload: HubListFilter, thunkApi) => {
    const response = await getHubsService({ ...payload });
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue(response.message);
    }
  }
);

const hubSlice = createSlice({
  name: "hubSlice",
  initialState: {
    hubsSearchList: <Hub[]>[],
  },
  reducers: {},
  extraReducers: {
    [getHubs.fulfilled.type]: (state, action) => {
      const data = action?.payload?.data as Hub[];
      state.hubsSearchList = data;
    },
  },
});

export default hubSlice.reducer;
