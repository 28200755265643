import React, { useEffect, useState } from "react";
import styles from "./event.module.css";
import { Input, DatePicker, Select, Button, message, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { EventCard } from "shared/components/events/EventCard";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "data/reducers";
import * as blogAction from "data/reducers/blogs.reducer";
import * as hubActions from "data/reducers/hub.reducer";
import { Event, EventListFilter } from "data/models/event.model";
import { getToAndFromDates } from "shared/utility/date";
import { EventCardAws } from "shared/components/events/EventCardAws";
import { EventData } from "data/models/eventNew.model";
import { BlogCard } from "shared/components/blogs/BlogCard";
import { Blog } from "data/models/blogs.model";
const { Search } = Input;
const { Option } = Select;

export const BlogsListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [eventType, setEventType] = useState<string>("All");
  const [pageNo, setPageNo] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalCount, settotalCount] = useState(21);
  const [totalPages, setTotalPages] = useState<number>(20);
  const [filterData, setFilterData] = useState<EventListFilter>({
    TextSearch: "",
    CommunityIDs: [],
    HubIDs: [],
    StartDate: "",
    EndDate: "",
    ShowDraft: false,
    PageNo: 1,
    PageSize: 100,
    region: ''
  });


  const { isLoading, isError, getAllBlogData, pagingInfo} = useSelector(
    (state: RootState) => state.blogsReducers
  );

  useEffect(() => {
    dispatch(blogAction.getAllBlog({ pageNumber: pageNo, pageSize }));
    
  }, [dispatch, pageNo, pageSize]);
  console.log("all blogs data", getAllBlogData)
  return (
    <div className="w-full min-h-screen">
     

      <div className="w-5/6 mx-auto my-8 grid grid-flow-row justify-items-center grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        {getAllBlogData.map((event: Blog) => (
          <BlogCard {...event} />
        ))}
      </div>

      <div className="w-full md:w-5/6 flex justify-end">
        <Pagination
          defaultCurrent={1}
          current={pagingInfo?.pageNo}
          onChange={(pageNumber: number, pageSize: number) => {
            setFilterData({ ...filterData, PageNo: pageNumber });
          }}
          total={pagingInfo?.totalPages ?? undefined}
        />
      </div>
    </div>
  );
};
