import { constants } from "config";
import { get, getHeaders, post1, } from "./services.common";
interface UploadFileBlogData {
    id: string;
    dp: string;
    file: File;
}

export const getAllBlogService = async (params: { pageNumber: any,pageSize:any }) => {
    const url = constants.API + `/Blog/all?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}`;
    const headers = { ...getHeaders(),  };
    const response = await get({ url, headers });
    return response;
   
};

export const uploadFileBlogService = async (data: UploadFileBlogData) => {
    const url = `${constants.API}/Blog/blob/insert-doc/${data.id}?dp=${data.dp}`;
    const headers = getHeaders() as any;
    delete headers['Content-Type']; // Let the browser set the correct boundary for multipart/form-data
  
    const formData = new FormData();
    formData.append('file', data.file);
  
    const response = await post1({
      url,
      headers,
      data: formData,
    });
  
    return response;
  }