import LoginCallback from "page-modules/auth/LoginCallback";
import { LoginPage } from "page-modules/auth/LoginPage";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom"

const AuthRouter = () => {
  let { url, path } = useRouteMatch();
  return <Switch>
    <Route path={`${url}`} exact
        render={() => <Redirect to={`${url}/login`} />}>
    </Route>
    <Route path={`${url}/login`}>
      <LoginPage/>
    </Route>
    <Route path={`${url}/callback`} exact>
      <LoginCallback/>
    </Route>
  </Switch>
}

export default AuthRouter