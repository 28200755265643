import React, { useEffect, useRef, useState } from "react";
import { Form, Input, Image } from "antd";
import MarkdownIt from "markdown-it";
import MdEditor from "react-markdown-editor-lite";

import "react-markdown-editor-lite/lib/index.css";

import ShellPage from "shared/nav-shell/ShellPage";
import Slider from "@ant-design/react-slick";
import { Link } from "react-router-dom";

// Initialize a markdown parser
const mdParser = new MarkdownIt(/* Markdown-it options */);

function handleEditorChange(args: { html: string; text: string }) {}

const CreateNewPost = () => {
  const filePickerRef = useRef<HTMLInputElement>(null);
  // filePickerRef?.current?.onchange = (event) => {};

  const contentStyle = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);

  const addFileToPreview = async (file: File) => {
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      setImagePreviews((currentPreviews) => [
        ...currentPreviews,
        fileReader.result as string,
      ]);
    };
    fileReader.readAsDataURL(file);
  };

  const setStringToInState = (files: File[]) => {
    setImagePreviews([]);
    files.forEach((file) => {
      addFileToPreview(file);
    });
  };

  useEffect(() => {
    setStringToInState(selectedFiles);
  }, [selectedFiles]);

  return (
    <div className="w-full flex flex-col items-center">
      <div className="w-full md:w-5/6 my-5">
        <Form
          name="Create Post Form"
          initialValues={{ remember: true }}
          layout="vertical"
        >
          <div className="w-full grid grid-flow-col-dense items-center pbe-4 justify-between border-b mbe-4">
            <Link to={`/posts`} className="font-bold flex items-center gap-4 capitalize">
              <i className="material-icons">arrow_back</i>
              Create New Post
            </Link>
            <div>
              <button className="text-grassy-green uppercase font-bold p-2.5 pli-5">
                Save as draft
              </button>
              <button className="bg-grassy-green text-white font-bold uppercase p-2.5 pli-5">
                Upload
              </button>
            </div>
          </div>
          <div className="grid grid-cols-3 lg:grid-cols-6 gap-4">
            <Form.Item
              label="Post Title"
              name="title"
              className="col-span-3"
              rules={[{ required: true, message: "Please Enter Post Title" }]}
            >
              <Input className="p-4" placeholder="Post Title" />
            </Form.Item>

            <Form.Item
              label="Post Author Name"
              name="author-name"
              className=""
              rules={[{ required: true, message: "Author Name is required" }]}
            >
              <Input placeholder="Author Name" />
            </Form.Item>

            <Form.Item
              label="Post Author Designation"
              name="author-designation"
              className=""
              rules={[{ required: true, message: "Author Designation" }]}
            >
              <Input placeholder="Author Designation" />
            </Form.Item>

            <Form.Item
              label="Post Author Company"
              name="author-company"
              className=""
              rules={[{ required: true, message: "Author Company Name" }]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>

            <div className="col-span-3">
              <Slider {...settings}>
                {imagePreviews.map((image, index) => {
                  return (
                    <div>
                      <img className="h-80" key={index} src={image} alt="" />
                    </div>
                  );
                })}
              </Slider>

              <div className="flex gap-4 flex-wrap">
                <input
                  type="file"
                  accept="image/*"
                  multiple={true}
                  onChange={(event) => {
                    const filesSelected = event?.target?.files;

                    const images: File[] = [];
                    if (filesSelected !== null) {
                      Array.from(filesSelected).forEach((file) => {
                        if (file.type.startsWith("image")) {
                          images.push(file);
                        } else {
                          console.log(`${file.name} is not an image`);
                        }
                      });
                    }

                    setSelectedFiles(images);
                  }}
                  style={{ display: "none" }}
                  name="images-picker"
                  ref={filePickerRef}
                />
                <button
                  onClick={(event) => {
                    event.preventDefault();
                    filePickerRef.current?.click();
                    // await Window.showOpenFilePicker();
                  }}
                  className="bg-primary-blue flex flex-col text-white uppercase items-center p-2"
                >
                  <i className="material-icons text-3xl">add</i>
                  Add Images
                </button>

                {imagePreviews.map((image, index) => {
                  return (
                    <img className="h-20" key={index} src={image} alt="" />
                  );
                })}
              </div>
            </div>

            <Form.Item
              name="post-markdown"
              rules={[
                {
                  required: true,
                  message: "Markdown content should not be empty",
                },
              ]}
              className="col-span-3"
            >
              <MdEditor
                style={{ height: "300px" }}
                renderHTML={(text) => mdParser.render(text)}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default CreateNewPost;
