import { constants } from "config";
import { HubListFilter } from "data/models/hub.model";
import { removeEmpty } from "shared/utility/object_cleaner";
import { get, getHeaders, post } from "./services.common";

export const getHubsService = async (args: HubListFilter) => {
  const url = constants.api + `Hubs/all`;
  const headers = { ...getHeaders() };

  const params = new URLSearchParams(removeEmpty(args) as any);
  const response = await get({ url: url + `?${params.toString()}`, headers });
  return response;
};
