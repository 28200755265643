export interface PostFilters {
  region?: string;
  TextSearch?: string;
  PostType?: string;
  CommunityIDs?: string[];
  SortBy?: string;
  PageSize?: number;
  PageNo?: number;
}

export interface ParentAuthor {
  id: string;
  userName: string;
  userDesignation?: any;
  userOrganization: string;
  userCountry?: any;
  isOrganizer: boolean;
  speakerInfo?: any;
  userLinks: any[];
  userPhoto?: any;
  referencingCommunities?: any;
}

export interface PostImage {
  id: string;
  url: string;
  thumbnailUrl: string;
  mimeType: string;
}

export interface Source {
  id?: any;
  sourceName?: any;
  sourceURL?: any;
}

export interface PostObject {
  id: string;
  postTitle: string;
  postDate: Date;
  postBody: string;
  postType: string;
  slug: string;
  parentAuthor: ParentAuthor[];
  postImages: PostImage[];
  source: Source;
}

export const PostType = {
  FeaturedPost: "featured-post",
  PastEventSnippet: "event-snippet",
};

export interface PagingInfo {
  pageSize: number | undefined;
  pageNo: number | undefined;
  totalCount: number | undefined;
}
