import LoginBackground from "./images/backgrounds/login-bg.jpg";

import EventCardPlaceholder from "./images/placeholders/event-card-placeholder.jpg";

export const backgrounds = {
  LoginBackground,
};

export const placeholders = {
  EventCardPlaceholder,
};
