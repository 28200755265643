import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "./auth.reducer";
import communityReducer from "./community.reducer";
import emailReducer from "./email.reducer";
import eventsReducer from "./events.reducer";
import hubReducer from "./hub.reducer";
import postReducer from "./post.reducer";
import shellPageReducer from "./shellPage.reducer";
import blogsReducer from "./blogs.reducer";

const rootReducer = combineReducers({
  shellReducer: shellPageReducer,
  authReducer: authReducer,
  eventsReducer: eventsReducer,
  postsReducer: postReducer,
  communityReducer: communityReducer,
  hubsReducer: hubReducer,
  emailsReducers: emailReducer,
  blogsReducers:blogsReducer
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
