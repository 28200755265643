import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Community, CommunityListFilter } from "data/models/community.model";
import { PagingInfo } from "data/models/post.model";
import { getCommunitiesService } from "data/services/community.service";

export const getCommunities = createAsyncThunk(
  "communities/getCommunities",
  async (payload: CommunityListFilter, thunkApi) => {
    const response = await getCommunitiesService({ ...payload });
    if (response.isSuccessful) {
      return response.data;
    } else {
      return thunkApi.rejectWithValue(response.message);
    }
  }
);

const communitySlice = createSlice({
  name: "CommunitySlice",
  initialState: {
    totalCount: 0,
    communitySearchList: {} as {[key: string]: Community},
  },
  reducers: {},
  extraReducers: {
    [getCommunities.fulfilled.type]: (state, action) => {
      state.communitySearchList = {};
      const data = action?.payload?.data as Community[];
      const pagingInfo = action?.payload?.pagingInfo as PagingInfo;
      data.forEach(community => {
        state.communitySearchList[community?.slug] = community;
      })
      state.totalCount = pagingInfo?.totalCount ?? 0;
    },
  },
});

export default communitySlice.reducer;
