import { get, getHeaders, post } from "./services.common";

import { constants } from "config";
import { EventListFilter } from "data/models/event.model";
import { removeEmpty } from "shared/utility/object_cleaner";
import { notNullUndef } from "shared/utility/null_check";

export const getEventsService = async (args: EventListFilter) => {
  const url = constants.api + `Events/all`;
  const headers = { ...getHeaders() };
  // const serialize = function (obj: any) {
  //   let str = [];
  //   for (let p in obj)
  //     if (obj.hasOwnProperty(p)) {
  //       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  //     }
  //   return str.join("&");
  // };
  //debugger
  const params = new URLSearchParams(removeEmpty(args) as any);
  const response = await get({ url: url + `?${params.toString()}`, headers });
  return response;
};
export const getEventServiceRegistered = async (args: {
  eventSlug: string;
 
  pagenumber?: number;
}) => {
 const url = constants.API + `/Events/geteventregistrationsbyslug/${args.eventSlug}?pagesize=${1200}&number=${1}`;
    console.log("url "+url);
  const headers = getHeaders();
  const response = await get({ url, headers });
  return response;
};///api/Events/geteventregistrationsbyslug/{slugid}
export const getEventsServiceaws = async (args: EventListFilter) => {
  const url = constants.API + `/Events/all`;
  const headers = { ...getHeaders() };
  // const serialize = function (obj: any) {
  //   let str = [];
  //   for (let p in obj)
  //     if (obj.hasOwnProperty(p)) {
  //       str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
  //     }
  //   return str.join("&");
  // };
  const params = new URLSearchParams(removeEmpty(args) as any);
  const response = await get({ url: url + `?${params.toString()}`, headers });
  return response;
};
export const getEventService = async (args: {
  eventSlug: string;
  pageNumber?: number;
}) => {
  const url =
    constants.api +
    `Events/geteventregistrations/${args.eventSlug}?number=${
      notNullUndef(args?.pageNumber) ? args?.pageNumber : 1
    }`;
    
  const headers = getHeaders();
  const response = await get({ url, headers });
  return response;
};
