import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { constants } from "config";
import { PagingInfo, PostFilters, PostObject } from "data/models/post.model";
import { getAllPostsService } from "data/services/post.service";

export const getAllPosts = createAsyncThunk(
  "postReducer/getAllPosts",
  async (payload: PostFilters, thunkApi) => {
    try {
      const response = await getAllPostsService({ ...payload });
      if (response.isSuccessful) {
        return response.data;
      }
    } catch (err) {
      return thunkApi.rejectWithValue({
        payload,
        message: "fetching failed",
        err,
        name: "postReducer/getAllPosts",
      });
    }
  }
);

const postSlice = createSlice({
  name: "postReducer",
  initialState: {
    posts: {} as { [key: string]: PostObject },
    pagingInfo: {
      pageSize: undefined,
      pageNo: undefined,
      totalCount: undefined,
    } as PagingInfo,
  },
  reducers: {},
  extraReducers: {
    [getAllPosts.fulfilled.type]: (state, action) => {
      state.posts = {};
      const data = action?.payload?.data;
      data?.forEach((element: PostObject) => {
        state.posts[element.slug] = element;
      });

      state.pagingInfo = action?.payload?.pagingInfo;
    },
  },
});

export default postSlice.reducer;
