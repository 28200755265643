import { get, getHeaders, post } from "./services.common";

import { constants } from "config";

import jwt_decode from "jwt-decode";
import { pca } from "index";

export const loginService = async (args: {
  emailId: string;
  password: string;
}) => {
  const url = constants.api + `Auth/login`;
  console.log({ api: constants.api });

  const headers = { ...getHeaders() };
  const response = await post({ data: args, url, headers });
  return response;
};

export const checkSigninUserService = () => {
  const auth_token = localStorage.getItem(constants.KEY_AUTH_TOKEN);
  const user_id = localStorage.getItem(constants.KEY_USER_ID);
  const user_name = localStorage.getItem(constants.KEY_USER_NAME);
  const user_firstname = localStorage.getItem(constants.KEY_USER_FNAME);
  const user_lastname = localStorage.getItem(constants.KEY_USER_LNAME);
  const user_email = localStorage.getItem(constants.KEY_USER_EMAIL);
  const user_image = localStorage.getItem(constants.KEY_USER_IMAGE);

  if (auth_token === undefined || auth_token === null) {
    return {
      isLoggedIn: false,
    };
  } else {
    const jsonToken: any = jwt_decode(auth_token);
    if (jsonToken.exp < (new Date().getTime() + 1) / 1000) {
      return {
        isLoggedIn: false,
      };
    } else {
      return {
        isLoggedIn: true,
        user_id,
        auth_token: auth_token,
        user_name,
        user_email,
        user_image,
        user_firstname,
        user_lastname,
      };
    }
  }
};

export const signoutUserService = () => {
  localStorage.clear();
  pca.logoutRedirect();
  return true;
};
