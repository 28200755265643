import { get, getHeaders, post } from "./services.common";

import { constants } from "config";
import { PostFilters } from "data/models/post.model";
import { removeEmpty } from "shared/utility/object_cleaner";

export const getAllPostsService = async (args: PostFilters) => {
  const url = constants.api + `Posts/all`;
  const headers = { ...getHeaders() };
  let params = new URLSearchParams(removeEmpty(args) as any);
  return await get({ url: url + `?${params.toString()}`, headers });
};
