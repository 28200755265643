import React, { useEffect } from "react";
import styles from "./eventComponents.module.css";
import * as Images from "assets/images.export";
import * as Icons from "assets/icons.export";
import { useHistory } from "react-router";
import { Popover } from "antd";
import {
  Event,
  EventStatus,
  ParentCommunity,
  ParentHub,
} from "data/models/event.model";
import { currentStatus } from "shared/utility/date";
import { EventData } from "data/models/eventNew.model";

export const EventCard = (props: EventData) => {
  const history = useHistory();
  const hubList = (
    <ul>
      {/* {props.parentHub?.map((hub: ParentHub) => (
        <li>{hub.hubName}</li>
      ))} */}
    </ul>
  );

  const communityList = (
    <ul>
      {/* {props.parentCommunity?.map((community: ParentCommunity) => (
        <li>{community.communityName}</li>
      ))} */}
    </ul>
  );

  const getEventStatus = () => {};

  return (
    <div
      className={
        styles.eventCard + " relative mb-8 cursor-pointer flex flex-col"
      }
      onClick={() => history.push(`/events/${props.id}`)}
    >
      
      {/* Image */}
      {/* <div className="p-4">
        <img
          src={Images?.placeholders?.EventCardPlaceholder}
          alt="event-image"
          className="w-full object-cover"
        />
      </div> */}

      <div className="flex flex-col justify-between flex-grow">
        {/* Event details */}
        <div className="px-4 pb-4">
          {/* Title */}
          <div className="text-3xl font-semibold mb-2  mt-4">
            {props?.flatData?.eventName}
          </div>
          {/* Speakers */}
          {props?.flatData?.popularSpeakers && (
            <div className="text-sm text-gray-500 mb-2">
              Speaker:{" "}
              <span className="font-semibold capitalize">
                {props?.flatData?.popularSpeakers.length > 0 &&
                props?.flatData?.popularSpeakers.length < 2
                  ? props?.flatData?.popularSpeakers[0]?.flatData?.userName
                  : props?.flatData?.popularSpeakers && "Multiple Speakers"}
              </span>
            </div>
          )}
          {/* Hubs */}
          {props?.flatData?.popularSpeakers && (
            <div className="flex flex-row mb-2">
              {props?.flatData?.popularSpeakers.length > 1 ? (
                <>
                  <img
                    src={Icons.Placeholders.HubsPlaceholderIcon}
                    className="h-5 w-5"
                    alt="hub-logo"
                  />
                  <Popover content={hubList} title="Hubs">
                    <span className="text-sm font-bold text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
                      Multiple Hubs
                    </span>
                  </Popover>
                </>
              ) : (
                <>
                  <img
                    src={Icons.Placeholders.HubsPlaceholderIcon}
                    className="h-5 w-5"
                    alt="hub-logo"
                  />
                  <span className="text-sm font-bold text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
                    {props?.flatData?.popularSpeakers[0]?.flatData?.userName}
                  </span>
                </>
              )}
            </div>
          )}
          {/* Communities */}
          {props?.flatData?.popularSpeakers && (
            <div className="flex flex-row mb-3">
              {props?.flatData?.popularSpeakers?.length > 1 ? (
                <>
                  <img
                    src={Icons.Placeholders.CommunityPlaceholderIcon}
                    className="h-5 w-5"
                    alt="community-logo"
                  />

                  <Popover content={communityList} title="Communities">
                    <span className="text-sm font-normal text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
                      Multiple Communities
                    </span>
                  </Popover>
                </>
              ) : (
                <>
                  <img
                    src={Icons.Placeholders.CommunityPlaceholderIcon}
                    className="h-5 w-5"
                    alt="community-logo"
                  />

                  <span className="text-sm font-normal text-blue-primary underline ml-2 hover:text-blue-800 cursor-pointer">
                    {props.flatData?.popularSpeakers[0]?.flatData?.userName}
                  </span>
                </>
              )}
            </div>
          )}
          {/* Date and Time */}
          <div className="mb-2 flex flex-row gap-4 divide-x-2">
            {/* Date */}
            <div className="flex items-center">
              <img
                src={Icons.UI.Calendar}
                className="h-5 w-5 inline"
                alt="event-date"
              />
              <span className="text-xs font-semibold text-gray-500 ml-1">
                {new Date(props?.flatData?.eventDate).toLocaleDateString()}
              </span>
            </div>
            {/* Time */}
            <div className="pl-2">
              <img
                src={Icons.UI.Clock}
                className="h-5 w-5 inline"
                alt="event-time"
              />
              <span className="text-sm font-semibold text-gray-500 ml-1">
                {new Date(props?.flatData?.eventStartTime).toLocaleTimeString()}{" "}
                - {new Date(props?.flatData?.eventEndTime).toLocaleTimeString()}
              </span>
            </div>
          </div>
        </div>
        {/* Event stats */}
        <div className="px-4 py-2 flex flex-row gap-4 divide-x-2 font-semibold text-white bg-blue-primary w-full">
          <div>
            Event Status:{" "}
            <span className={styles.ongoing}>
              {
                EventStatus[
                  currentStatus({
                    startTime: new Date(props?.flatData?.eventStartTime),
                    endTime: new Date(props?.flatData?.eventEndTime),
                  })
                ]
              }
            </span>
          </div>

          <div className="pl-3">
            No. of Registrations:{" "}
            <span className="font-bold">
              {props?.flatData?.totalRegistrations}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
