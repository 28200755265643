import React, { useState, useCallback } from "react";
import * as Icons from "assets/icons.export";
import * as blogActions from "data/reducers/blogs.reducer";
import { useDispatch } from "react-redux";
import { Blog } from "data/models/blogs.model";

export const BlogCard = (props: Blog) => {
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [description, setDescription] = useState("");

  const dispatch = useDispatch();

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  }, []);

  const handleDescriptionChange = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(event.target.value);
  }, []);

  const handleUpload = useCallback(() => {
    if (file) {
      dispatch(blogActions.uploadFileBlog({ id: props.blogsId, dp: description, file }));
      setShowModal(false);
    } else {
      alert("Please select a file to upload.");
    }
  }, [file, description, dispatch, props.blogsId]);

  return (
    <div className="relative mb-8 cursor-pointer flex flex-col">
      {showModal && (
        <div className="fixed top-0 right-0 z-50 p-8 w-full h-full overflow-x-hidden overflow-y-scroll bg-black bg-opacity-95 md:inset-0 md:h-full transition ease-in-out delay-150 duration-300 flex justify-center items-center">
          <div className="bg-white bg-opacity-90 rounded-3xl lg:w-1/3 p-10 mt-12 flex flex-col gap-4">
            <div className="flex w-full justify-end">
              <div className="cursor-pointer" onClick={() => setShowModal(false)}>X</div>
            </div>
            <div className="font-semibold">Upload File</div>
            <div className="rounded-lg">
              <input
                className="block w-full text-sm text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"
                id="file_input"
                type="file"
                onChange={handleFileChange}
              />
            </div>
            <div className="font-semibold">File Description</div>
            <div>
              <textarea
                className="shadow appearance-none border rounded w-full resize-none h-24 py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                id="file_description"
                placeholder="Description"
                value={description}
                onChange={handleDescriptionChange}
              />
            </div>
            <button className="w-full bg-blue-secondary text-white py-4 rounded mt-4" onClick={handleUpload}>Upload</button>
          </div>
        </div>
      )}

      <div className="flex flex-col justify-between flex-grow">
        <div className="w-full">
          <img src={props.imageUrl || ''} alt="" />
        </div>
        <div className="px-4 pb-4">
          <div className="text-3xl font-semibold mb-2 mt-4">{props?.title}</div>
          {props?.userName && (
            <div className="text-sm text-gray-500 mb-2">
              Speaker: <span className="font-semibold capitalize">{props.userName}</span>
            </div>
          )}
          {props.blogsId}
          <div className="mb-2 flex flex-row gap-4 divide-x-2">
            <div className="flex items-center">
              <img
                src={Icons.UI.Calendar}
                className="h-5 w-5 inline"
                alt="event-date"
              />
              <span className="text-xs font-semibold text-gray-500 ml-1">
                {new Date(props?.timeStamp).toLocaleDateString()}
              </span>
            </div>
            <div className="pl-2">
              <img
                src={Icons.UI.Clock}
                className="h-5 w-5 inline"
                alt="event-time"
              />
              <span className="text-sm font-semibold text-gray-500 ml-1">
                {new Date(props?.createdAt).toLocaleTimeString()} - {new Date(props?.timeStamp).toLocaleTimeString()}
              </span>
            </div>
          </div>
          <button className="w-full bg-blue-secondary text-white py-4 rounded mt-4" onClick={() => setShowModal(true)}>Upload File</button>
        </div>
      </div>
    </div>
  );
};