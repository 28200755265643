import { InteractionRequiredAuthError, RedirectRequest, SilentRequest } from "@azure/msal-browser";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { constants } from "config";
import {
  checkSigninUserService,
  loginService,
  signoutUserService,
} from "data/services/auth.service";
import { pca } from "index";
import jwt_decode from "jwt-decode";

export const login = createAsyncThunk(
  "auth/oauthLogin",
  async (payload: { emailId: string; password: string }, thunkApi) => {
    const response = await loginService({
      emailId: payload.emailId,
      password: payload.password,
    });
    if (response.isSuccessful) {
      console.log(response.data);
      const decoded_token: any = jwt_decode(response?.data?.data?.token);
      const contentToSave = {
        isLoggedIn: true,
        auth_token: response?.data?.data?.token,
        user_id:
          decoded_token[
            "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier"
          ],
        user_name: response?.data?.data?.username,
        user_firstname: response?.data?.data?.user?.firstname,
        user_lastname: response?.data?.data?.user?.lastname,
        user_email: response?.data?.data?.email,
        user_image: response?.data?.data?.user?.imageURL,
      };
      thunkApi.dispatch(authSlice.actions.saveTokenToStorage(contentToSave));
      thunkApi.dispatch(authSlice.actions.saveTokenToRedux(contentToSave));
    } else {
      thunkApi.rejectWithValue(response.message);
      throw new Error(response.message);
    }
  }
);

export const checkAuthStatus = createAsyncThunk(
  "auth/checkAuthStatus",
  async (payload: any, thunkApi) => {
    const signInStatus = checkSigninUserService();
    if (signInStatus.isLoggedIn) {
      thunkApi.dispatch(authSlice.actions.saveTokenToRedux(signInStatus));
    } else {
      thunkApi.dispatch(authSlice.actions.saveTokenToRedux(signInStatus));
      thunkApi.dispatch(
        authSlice.actions.saveTokenToStorage(signInStatus as any)
      );
    }
  }
);

export const signoutUser = createAsyncThunk(
  "auth/signout",
  async (payload: any, thunkApi) => {
    signoutUserService();
  }
);

export const getAuthToken = createAsyncThunk(
  "auth/getO365Token",
  async (payload: any, thunkApi) => {
    try {
      const accounts = pca.getAllAccounts();
      if (accounts.length > 0) {
        const silentRequest: SilentRequest = {
          scopes: ["User.Read"],
          account: accounts[0],
          forceRefresh: false,
          authority: constants.AUTHORITY
        };

        const request: RedirectRequest = {
          scopes: ["User.Read"],
          loginHint: accounts[0]?.username,
          authority: constants.AUTHORITY
        };

        const tokenResponse = await pca
          .acquireTokenSilent(silentRequest)
          .catch((err) => {
            if (err instanceof InteractionRequiredAuthError) {
              return pca.acquireTokenRedirect(request);
            }
          });

        if (tokenResponse?.account) {
          const roles: string[] = (tokenResponse.account?.idTokenClaims as any)
            ?.roles;
          if (roles?.includes("Styava.Admin")) {
            const account = tokenResponse?.account;
            const idTokenClaims: any = account?.idTokenClaims;
            thunkApi.dispatch(
              authSlice.actions.saveTokenToRedux({
                isLoggedIn: true,
                user_email: idTokenClaims?.preferred_username as string,
                user_name: idTokenClaims?.name as string,
                user_id: account?.localAccountId,
                user_image: "",
                user_firstname: "",
                user_lastname: "",
                auth_token: tokenResponse?.accessToken,
              })
            );
            thunkApi.dispatch(
              authSlice.actions.saveTokenToStorage({
                isLoggedIn: true,
                user_email: idTokenClaims?.preferred_username as string,
                user_name: idTokenClaims?.name as string,
                user_id: account?.localAccountId,
                user_image: "",
                user_firstname: "",
                user_lastname: "",
                auth_token: tokenResponse?.accessToken,
              })
            );

            console.log(tokenResponse);

            // We stringify the response and then again parse to make the response
            // serialisable. Redux will throw error is any data in state or actions
            // is not serialised
            return thunkApi.fulfillWithValue(
              JSON.parse(JSON.stringify(tokenResponse))
            );
          } else {
            return thunkApi.rejectWithValue(tokenResponse);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  }
);

export const removeO365Auth = createAsyncThunk(
  "authSlice/remove0365Token",
  (state, action) => {
    pca.logoutRedirect();
  }
);

const authSlice = createSlice({
  name: "authSlice",
  initialState: {
    isLoggedIn: <Boolean | undefined>undefined,
    auth_token: "",
    user_id: "",
    user_name: "",
    user_email: "",
    user_image: "",
    user_country: "",
    user_designation: "",
    user_company: "",
    user_firstname: "",
    user_lastname: "",
    isLoading: false,
    isError: false,
    error: "",
    isAuthorised: <Boolean | undefined>undefined,
  },
  reducers: {
    setStatusLoading: (state, action) => {
      state.isLoading = true;
      state.isError = false;
    },
    saveTokenToStorage: (
      state,
      action: {
        payload: {
          isLoggedIn: boolean;
          auth_token: string;
          user_name: string;
          user_id: string;
          user_firstname: string;
          user_lastname: string;
          user_email: string;
          user_image: string;
        };
      }
    ) => {
      if (action.payload.isLoggedIn) {
        localStorage.setItem(constants.KEY_LOGGED_IN, "true");
        localStorage.setItem(
          constants.KEY_AUTH_TOKEN,
          action.payload?.auth_token
        );
        localStorage.setItem(constants.KEY_USER_ID, action.payload?.user_id);
        localStorage.setItem(
          constants.KEY_USER_NAME,
          action.payload?.user_name
        );
        localStorage.setItem(
          constants.KEY_USER_FNAME,
          action.payload?.user_firstname
        );
        localStorage.setItem(
          constants.KEY_USER_LNAME,
          action.payload?.user_lastname
        );
        localStorage.setItem(
          constants.KEY_USER_EMAIL,
          action.payload?.user_email
        );
        localStorage.setItem(
          constants.KEY_USER_IMAGE,
          action.payload?.user_image
        );
      } else {
        localStorage.clear();
      }
    },
    saveTokenToRedux: (state, action) => {
      if (action.payload.isLoggedIn) {
        state.isLoggedIn = true;
        state.user_id = action.payload?.user_id;
        state.auth_token = action.payload?.auth_token;
        state.user_name = action.payload?.user_name;
        state.user_email = action.payload?.user_email;
        state.user_image = action.payload?.user_image;
        state.user_firstname = action.payload?.user_firstname;
        state.user_lastname = action.payload?.user_lastname;
      } else {
        state.isLoggedIn = false;
        state.auth_token = "";
        state.user_name = "";
        state.user_email = "";
      }
    },
  },
  extraReducers: {
    [login.pending.type]: (state, action) => {
      state.isLoading = true;
      state.isError = false;
    },
    [login.fulfilled.type]: (state, action) => {
      state.isLoading = false;
      state.isAuthorised = true;
    },
    [login.rejected.type]: (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isAuthorised = false;
      state.error = action.error.message;
    },
    [getAuthToken.fulfilled.type]: (state, action) => {
      state.isAuthorised = true;
    },
    [getAuthToken.rejected.type]: (state, action) => {
      state.isAuthorised = false;
    },
  },
});

export const { saveTokenToRedux, saveTokenToStorage, setStatusLoading } =
  authSlice.actions;

export default authSlice.reducer;
