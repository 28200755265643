import DropdownChevron from "./icons/ui/dropdown-chevron.svg";
import Hamburger from "./icons/ui/hamburger.svg";
import MenuClose from "./icons/ui/menu-close.svg";
import Calendar from "./icons/ui/calendar-date.svg";
import Clock from "./icons/ui/clock.svg";
import BackArrow from "./icons/ui/back-arrow.svg";
import Events from "./icons/ui/events.svg";
import Posts from "./icons/ui/posts.svg";
import Communities from "./icons/ui/community.svg";
import AddText from "./icons/ui/add-text.svg";
import AddHtml from "./icons/ui/add-html.svg";
import AddPerson from "./icons/ui/add-person.svg";
import GroupMembers from "assets/icons/ui/group-members.png";

import Logo from "./icons/branding/logo.svg";
import MicrosoftLogo from "./icons/branding/microsoft-logo.svg";
import MicrosoftLogoTransparent from "./icons/branding/microsoft-logo-transparent.svg";
import AzureCommunitiesApac from "./icons/branding/azure-communities-apac-logo.svg";
import AzureCommunitiesApacWhite from "./icons/branding/azure-communities-apac-logo-white.svg";
import StyavaDev from "./icons/branding/styava-dev-logo.png";
import MsftFull from "assets/icons/branding/msft-full.svg";
import MsftFullWhite from "assets/icons/branding/msft-full-white.png";
import MsftFullGray from "assets/icons/branding/msft-full.png";

import HubsPlaceholderIcon from "assets/icons/placeholders/hubs-placeholder-icon.svg";
import CommunityPlaceholderIcon from "assets/icons/placeholders/community-placeholder-icon.svg";

export const UI = {
  DropdownChevron,
  Hamburger,
  MenuClose,
  Calendar,
  Clock,
  BackArrow,
  Events,
  Posts,
  Communities,
  AddHtml,
  AddText,
  AddPerson,
  GroupMembers
};

export const Branding = {
  Logo,
  MicrosoftLogo,
  MicrosoftLogoTransparent,
  AzureCommunitiesApac,
  AzureCommunitiesApacWhite,
  StyavaDev,
  MsftFull,
  MsftFullWhite,
  MsftFullGray,
};

export const Placeholders = {
  HubsPlaceholderIcon,
  CommunityPlaceholderIcon,
};
