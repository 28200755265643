import { Select, DatePicker, Input, Button, Tooltip, Form, message } from "antd";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import * as emailActions from "data/reducers/email.reducer";
import { RootState } from "data/reducers";
import { EmailTemplate } from "data/models/email.model";
import {
  notNullUndef,
  notNullUndefAndIsArray,
} from "shared/utility/null_check";

export default function BroadCastEmail() {
  const dispatch = useDispatch();

  const [selectedTemplate, setSelectedTemplate] =
    React.useState<EmailTemplate>();

  React.useEffect(() => {
    dispatch(emailActions.getAllTemplateAws());
  }, []);

  const templates = useSelector(
    (state: RootState) => state.emailsReducers.templates
  );
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const { eventDetails, eventRegistrations } = useSelector(
    (state: RootState) => state.eventsReducer
  );

  return (
    <div>
      <div className="w-full bg-white">
        <div className="flex flex-col gap-4">
          <div className="w-full pbe-4 border-b">
            <div className="lg:w-1/2 w-full flex flex-col">
              <label htmlFor="broadcast-template" className="plb-2">
                Select the template type
              </label>
              <Select
                showSearch
                size="large"
                className="w-full"
                id="broadcast-template"
                placeholder="Template"
                onChange={(selected: string) => {
                  const currentlySelectedTemplate = templates[selected];
                  setSelectedTemplate(currentlySelectedTemplate);
                }}
              >
                {Object.entries(templates)?.map(([key, template]) => (
                  <Select.Option value={template.slug}>
                    {template.name}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <Form
            labelCol={{ span: 6 }}
            labelAlign={"left"}
            wrapperCol={{ span: 16 }}
            initialValues={{ remember: true }}
            className="flex flex-col gap-4"
            onFinish={(values) => {
              if (selectedTemplate?.slug) {
                dispatch(
                  emailActions.sendTemplateEmailAws({
                    urlParams: {
                      eventId: eventDetails?.id,
                      templateSlug: selectedTemplate?.slug,
                    },
                    body: values,
                  })
                );
              }
            }}
          >
            {selectedTemplate?.parameters &&
            selectedTemplate?.parameters?.length > 0 ? (
              <div>
                <h3 className="text-lg">Template Parameters</h3>

                <div className="grid md:grid-cols-2 gap-4">
                  {selectedTemplate?.parameters?.map((parameter) => {
                    return (
                      <Form.Item
                        label={parameter}
                        name={parameter}
                        rules={[
                          {
                            required: true,
                            message: `Please enter ${parameter}`,
                          },
                        ]}
                        className="flex flex-col gap-2"
                      >
                        <Input />
                      </Form.Item>
                    );
                  })}
                </div>
              </div>
            ) : (
              <p className="text-gray-600">
                *This event template doesn't require any parameters
              </p>
            )}

            <Form.Item className="flex items-center justify-center w-full">
              <Tooltip title="This will send email to each and every participant of this event">
                <Button
                  title="Submit"
                  size="large"
                  htmlType="submit"
                  type="primary"
                  className={isSubmitting ? 'waiting-button' : 'normal-button'}
                  style={{ cursor: isSubmitting ? 'wait !important' : 'pointer', backgroundColor: isSubmitting ? 'red' : 'blue !important', }} // Change cursor style based on isSubmitting
                  disabled={isSubmitting} // Disable the button during submission
                
                >
                  Submit
                </Button>
              </Tooltip>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
}
