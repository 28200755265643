import { message } from "antd";
import { RootState } from "data/reducers";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import * as authActions from "data/reducers/auth.reducer";
import Loader from "../Loader";
import { constants } from "config";

type ProtectedRouteProps = {
  children: React.ReactElement;
};

export const ProtectedRoute = (props: ProtectedRouteProps) => {
  const dispatch = useDispatch();
  const [isLoggedIn, setIsLoggedIn] = useState(
    localStorage.getItem(constants.KEY_LOGGED_IN)
  );

  useEffect(() => {
    dispatch(authActions.checkAuthStatus({}));
    if(isLoggedIn !== "true"){
      message.warning("Login to continue")
    }
  }, []);

  useEffect(() => {
    setIsLoggedIn(localStorage.getItem(constants.KEY_LOGGED_IN));
  }, [localStorage.getItem(constants.KEY_LOGGED_IN)]);

  return isLoggedIn == "true" ? props.children : <Redirect to="/auth/login" />;
};

export default ProtectedRoute;
