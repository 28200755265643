import CreateEventPage from "page-modules/events/CreateEventPage";
import { EventDetailsPageDev } from "page-modules/aws/EventDetailsPageDev";
import { EventDetailsPage } from "page-modules/events/EventDetailsPage";
import { EventListPage } from "page-modules/events/EventListPage";
import * as React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

const EventRouter = () => {
  let { url, path } = useRouteMatch();

  return (
    <><Switch>
      <Route path={`${url}/create`} exact>
        <CreateEventPage />
      </Route>
      {/* <Route path={`${url}/:eventId`}  exact>
        <EventDetailsPageDev />
      </Route> */}
      <Route path={`${url}/:eventId`} exact>
      <EventDetailsPage />
       </Route>
    <Route path={`${url}`} exact>
        <EventListPage />
      </Route>
  </Switch></>
  );
};

export default EventRouter;
