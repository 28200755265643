export const constants = {
  api: process.env.REACT_APP_API_ENDPOINT,
  assets: "https://styavacms.southeastasia.azurecontainer.io/api/assets/styavacommunities/",
  KEY_AUTH_TOKEN: "AUTH_KEY",
  KEY_USER_ID: "USER_ID",
  KEY_USER_NAME: "USER_NAME",
  KEY_USER_FNAME: "USER_FNAME",
  KEY_USER_LNAME: "USER_LNAME",
  KEY_USER_EMAIL: "USER_EMAIL",
  KEY_USER_IMAGE: "USER_IMAGE",
  KEY_LOGGED_IN: "LOGGED_IN",
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  REDIRECT_URI: `${window.location.origin}/auth/callback`,
  AUTHORITY: process.env.REACT_APP_AUTHORITY,
  API:process.env.REACT_APP_API_URL
};
