/**
 * remove undefined, null and empty array values from an object and return clean object
 * @param obj any
 * @returns obj without undefined and null values
 */
function removeEmpty(obj: any) {
  // remove all the undefined, null and empty string values
  let cleaned = Object.fromEntries(
    Object.entries(obj).filter(
      ([_, v]) => v !== null && v !== undefined && v !== ""
    )
  );
  // remove all the arrays that have less than 1 elements
  cleaned = Object.fromEntries(
    Object.entries(cleaned).filter(([_, v]) =>
      Array.isArray(v) && v.length < 1 ? false : true
    )
  );
  return cleaned;
}

export { removeEmpty };
