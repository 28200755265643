/**
 * returns true if value is not null and undefined
 * @param value any
 * @returns boolean
 */
const notNullUndef = (value: any) => value !== null && value !== undefined;

/**
 * returns true if value is not null and undefined and empty string
 * @param value any
 * @returns boolean
 */
const notNullUndefOrEmptyString = (value: any) =>
  value !== null && value !== undefined && value !== "";

/**
 * return true if value is not null and undefined and is an Array
 * @param value
 * @returns
 */
const notNullUndefAndIsArray = (value: any) =>
  value !== null &&
  value !== undefined &&
  Array.isArray(value) &&
  value?.length > 0;

export {
  notNullUndef,
  notNullUndefOrEmptyString as notNullUndefEmptyString,
  notNullUndefAndIsArray,
};
