import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import PostRouter from "./post.router";
import EventRouter from "./event.router";
import ProtectedRoute from "shared/components/auth/ProtectedRoute";
import NotFound from "page-modules/error/NotFound";
import CommunityRouter from "./community.router";
import EventRouterDev from "./eventdev.route";
import BlogsRouter from "./blogs.router";

const DashboardRouter = () => {
  return (
    <ProtectedRoute>
      <Switch>
        <Route path="/" exact render={() => <Redirect to="/events" />} />
        <Route path="/home" exact>
          Home
        </Route>
        <Route path="/events">
          <EventRouter />
        </Route>
        <Route path="/aws">
          <EventRouterDev />
        </Route>
        <Route path="/posts">
          <PostRouter />
        </Route>
        <Route path="/communities">
          <CommunityRouter />
        </Route>
        <Route path="/allblogs">
          <BlogsRouter />
        </Route>
        {/* 404 Not found */}
        <Route path="*" exact>
          <NotFound />
        </Route>
      </Switch>
    </ProtectedRoute>
  );
};

export default DashboardRouter;
