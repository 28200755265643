import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BlogsApiResponse } from "data/models/blogs.model";
import { getAllBlogService, uploadFileBlogService } from "data/services/blogs.service";

export interface PaginationInfo {
  pageNo: number;
  pageSize: number;
  totalRecords: number;
  totalPages:number;
}
export const getAllBlog = createAsyncThunk(
  "blog/getAllBlog",
  async (params: { pageNumber: any; pageSize: any }, thunkApi) => {
    try {
      const response = await getAllBlogService({
        pageNumber: params.pageNumber,
        pageSize: params.pageSize,
      });
      if (response.isSuccessful) {
        return response.data;
      } else {
        thunkApi.rejectWithValue(response);
        throw new Error(response?.data);
      }
    } catch (error) {
      throw error;
    }
  }
);

export const uploadFileBlog = createAsyncThunk(
  "blogs/blogfileupload",
  async (payload: {id:string, dp:string, file:File}, thunkAPI) => {
    const addblogResponse = await uploadFileBlogService({ ...payload });
    if (addblogResponse.isSuccessful) {
      return addblogResponse.data;
    } else {
      thunkAPI.rejectWithValue("unable to add blog file");

      throw new Error("unable to add blog file");
    }
  }
);



export const blogSlice = createSlice({
  name: "blogSlice",
  initialState: {
    isError: false,
    loading: false,
    pagingInfo: <PaginationInfo>{},
    isLoading: false,
    getAllBlogData: [] as any,
    fileUpploadResponse:false,
    getLatestBlogData:[],
    getBlogByIdData:{},
    getAllblogLoading: false,
    createBlogData:{},
    updateBlogData:{}
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getAllBlog.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(getAllBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.getAllBlogData = action.payload.data;
        state.pagingInfo = action?.payload?.pagination;       
      })
      .addCase(getAllBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
      .addCase(uploadFileBlog.pending, (state) => {
        state.isLoading = true;
        state.isError = false;
      })
      .addCase(uploadFileBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isError = false;
        state.updateBlogData = action.payload.data;      
      })
      .addCase(uploadFileBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
      })
     
  },
});

export default blogSlice.reducer;


