import CommunityListPage from "page-modules/communities/CommunityListPage";
import CreateCommunityPage from "page-modules/communities/CreateCommunityPage";
import * as React from "react";
import { Route, Switch, Redirect, useRouteMatch } from "react-router-dom";

const CommunityRouter = () => {
    let { url, path } = useRouteMatch();

    return (
        <Switch>
            <Route path={url} exact>
                <Redirect to={`/list`} />
            </Route>
            <Route path={`${url}/create`} exact>
                <CreateCommunityPage />
            </Route>
            <Route path={`/list`} exact>
                <CommunityListPage />
            </Route>
        </Switch>
    );
};

export default CommunityRouter;
