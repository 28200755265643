import React, { useEffect, useState } from "react";
import styles from "./event.module.css";
import { Input, DatePicker, Select, Button, message, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { EventCard } from "shared/components/events/EventCard";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "data/reducers";
import * as communityActions from "data/reducers/community.reducer";
import * as eventsActions from "data/reducers/events.reducer";
import * as hubActions from "data/reducers/hub.reducer";
import { Event, EventListFilter } from "data/models/event.model";
import { getToAndFromDates } from "shared/utility/date";
import { EventCardAws } from "shared/components/events/EventCardAws";
import { EventData } from "data/models/eventNew.model";
const { Search } = Input;
const { Option } = Select;

export const EventListPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [filterData, setFilterData] = useState<EventListFilter>({
    TextSearch: "",
    CommunityIDs: [],
    HubIDs: [],
    StartDate: "",
    EndDate: "",
    ShowDraft: false,
    PageNo: 1,
    PageSize: 100,
    region: ''
  });

  const communitySearchList = useSelector(
    (state: RootState) => state.communityReducer.communitySearchList
  );

  const hubSearchList = useSelector(
    (state: RootState) => state.hubsReducer.hubsSearchList
  );

  const [selectedCommunities, setSelectedCommunities] = useState<
    { id: string; name: string; slug: string }[]
  >([]);

  const { isLoading, isError, error, event4,eventList, eventListPortal,pagination } = useSelector(
    (state: RootState) => state.eventsReducer
  );

  useEffect(() => {
    dispatch(eventsActions.getEvents({ ...filterData }));
  }, [filterData]);
  useEffect(() => {
    dispatch(eventsActions.getEventsaws({ ...filterData }));
  }, [filterData]);
  console.log("hi eventlistpage",eventList)
  return (
    <div className="w-full min-h-screen">
      <div className={"w-full bg-white " + styles.topBarShadow}>
        <div className="w-5/6 mx-auto my-auto py-7">
          <div className="w-80 inline-block align-top mr-7">
            <Search
              placeholder="Search Event"
              allowClear
              enterButton="Search"
              size="large"
              onSearch={(data: string) => {
                setFilterData({ ...filterData, TextSearch: data });
              }}
            />
          </div>

          {/* Community */}
          <div className="mr-7 inline-block">
            <Select
              showSearch
              size="large"
              mode="tags"
              placeholder="Communities"
              maxTagCount="responsive"
              allowClear
              style={{ width: 120 }}
              onSearch={(value) => {
                dispatch(
                  communityActions.getCommunities({
                    PageSize: 5,
                    PageNo: 1,
                    region: undefined,
                    TextSearch: value,
                    HubIDs: undefined,
                    SortBy: undefined,
                    Tags: undefined,
                  })
                );
              }}
            >
              {Object.entries(communitySearchList).map(([slug, community]) => {
                return (
                  <Option value={community?.slug} key={community?.id}>
                    {community?.communityName}
                  </Option>
                );
              })}
            </Select>
          </div>

          {/* Hubs */}
          <div className="mr-7 inline-block">
            <Select
              showSearch
              size="large"
              mode="tags"
              placeholder="Hubs"
              maxTagCount="responsive"
              allowClear
              style={{ width: 120 }}
              onSearch={(value) => {
                dispatch(
                  hubActions.getHubs({
                    PageSize: 5,
                    PageNo: 1,
                    region: undefined,
                    TextSearch: value,
                  })
                );
              }}
            >
              {hubSearchList.map((hub: any) => {
                return (
                  <Option value={hub?.slug} key={hub?.id}>
                    {hub?.hubName}
                  </Option>
                );
              })}
            </Select>
          </div>

          <DatePicker
            onChange={(date, dateString) => {
              console.log({ date, dateString });

              if (date !== null) {
                const { toDate, fromDate } = getToAndFromDates(
                  new Date(dateString)
                );
                setFilterData({
                  ...filterData,
                  StartDate: fromDate,
                  EndDate: toDate,
                });
              } else {
                setFilterData({
                  ...filterData,
                  StartDate: "",
                  EndDate: "",
                });
              }
            }}
            size="large"
          />

          {/* Action buttons */}
          <div className="float-right">
            <Button
              type="primary"
              ghost
              size="large"
              className="w-40 mr-7 uppercase"
              style={{ fontSize: "0.875rem" }}
            >
              Drafts
            </Button>
            <Button
              type="primary"
              size="large"
              className="uppercase bg-black"
              style={{ fontSize: "0.875rem" }}
              onClick={() => history.push("/events/create")}
            >
              <PlusOutlined style={{ verticalAlign: "text-top" }} />
              Create new event
            </Button>
          </div>
        </div>
      </div>

      <div className="w-5/6 mx-auto my-8 grid grid-flow-row justify-items-center grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
        {event4?.map((event: EventData) => (
          <EventCard {...event} />
        ))}
        {eventListPortal?.map((event: Event) => (
          <EventCardAws {...event} />
        ))}
      </div>

      <div className="w-full md:w-5/6 flex justify-end">
        <Pagination
          defaultCurrent={1}
          current={pagination?.pageNo}
          onChange={(pageNumber: number, pageSize: number) => {
            setFilterData({ ...filterData, PageNo: pageNumber });
          }}
          total={pagination?.totalCount ?? undefined}
        />
      </div>
    </div>
  );
};
