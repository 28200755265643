import { EventStatus } from "data/models/event.model";
import * as datefns from "date-fns";

/**
 * returns to and From time for a specified date.
 * For E.g. if date is 26/7/2019 then to and from
 * will be 25/7/2019 23:59 and 26/7/2019 23:59
 * @param date
 * @returns { toDate: ISOString, fromDate: ISOString }
 */
const getToAndFromDates = (date: Date) => {
  const toDate = datefns.addDays(date, 1);
  const fromDate = datefns.subMinutes(date, 1);
  return { toDate: toDate.toISOString(), fromDate: fromDate.toISOString() };
};

/**
 * check `new Date()` is between startTime and endTime
 * @param startTime dateTime string
 * @param endTime dateTime string
 * @returns boolean
 */
const checkIsEventLive = (startTime: string, endTime: string): boolean => {
  const params = {
    start: Date.parse(startTime),
    end: Date.parse(endTime),
  };
  const isLive = datefns.isWithinInterval(new Date(), params);
  return isLive;
};

/**
 * check if the date has already passed
 * @param args : { date: date string }
 * @returns boolean
 */
const hasDatePassed = (args: { date?: string }) => {
  if (args?.date) {
    if (new Date().getTime() >= new Date(args?.date).getTime()) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

/**
 * Returns current status based up on the start time and endTime
 * @param args
 */
const currentStatus = (args: {
  startTime?: Date;
  endTime?: Date;
}): EventStatus => {
  if (args?.startTime && args.endTime) {
    const startTime = new Date(args?.startTime);
    const endTime = new Date(args?.endTime);
    if (startTime > new Date()) {
      return EventStatus.Upcoming;
    } else if (startTime < new Date() && endTime > new Date()) {
      return EventStatus.Ongoing;
    }
    else{
        return EventStatus.Ended;
    }
  }
  return EventStatus.Upcoming;
};

export { getToAndFromDates, checkIsEventLive, hasDatePassed, currentStatus };
