import { Button, Pagination, Tabs } from "antd";
import Search from "antd/lib/input/Search";
import React, { useEffect, useState } from "react";

import styles from "page-modules/post-module/post.module.css";
import PostCard from "shared/components/posts/PostCard";
import { useDispatch, useSelector } from "react-redux";

import * as postActions from "data/reducers/post.reducer";
import { RootState } from "data/reducers";
import { PostType } from "data/models/post.model";
import { Link, useLocation, useRouteMatch } from "react-router-dom";

export interface PostFilterState {
  searchText: string;
  PageNumber: number;
}

export default function ListPosts() {
  const { TabPane } = Tabs;

  const inputRef = React.useRef<any>(null);
  const { url } = useRouteMatch();

  const { posts, pagingInfo } = useSelector(
    (state: RootState) => state.postsReducer
  );
  const [selectedTab, setSelectedTab] = useState("featured-post");
  const [filterState, setFilterState] = useState<PostFilterState>();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(postActions.getAllPosts({ PostType: PostType.FeaturedPost }));
  }, []);

  useEffect(() => {
    dispatch(
      postActions.getAllPosts({
        PostType: selectedTab,
        TextSearch: filterState?.searchText,
      })
    );
  }, [selectedTab]);

  const searchPosts = (searchText: string) => {
    dispatch(
      postActions.getAllPosts({
        PostType: selectedTab,
        TextSearch: searchText,
      })
    );
  };

  const TabItems = () => {
    return (
      <div className="flex w-full justify-between">
        <div className="w-80 inline-block align-top mr-7">
          <Search
            placeholder="Search Post"
            enterButton
            onSearch={(data: string) => {
              searchPosts(data);
            }}
          />
        </div>

        <div className="flex gap-4">
          <Button type="primary">Drafts</Button>
          <Link to={`${url}/create`}>
            <div className="flex justify-center gap-2">
              <i className="material-icons text-base">add</i>
              <p>Create New</p>
            </div>
          </Link>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col items-center">
      <div
        className={`w-full flex flex-col justify-between items-center bg-white ${styles.topTabShadow}`}
      >
        <div className="w-5/6 plb-2">
          <Tabs
            defaultActiveKey="1"
            onChange={(activeKey) => {
              if (activeKey == "1") {
                setSelectedTab(PostType.FeaturedPost);
                console.log(`changed to fp`);
              } else if (activeKey == "2") {
                setSelectedTab(PostType.PastEventSnippet);
                console.log(`changed to pes`);
              }
            }}
          >
            <TabPane tab="Featured Post" className="mlb-4" key="1">
              <TabItems />
            </TabPane>
            <TabPane tab="Past Event Snippets" className="mlb-4" key="2">
              <TabItems />
            </TabPane>
          </Tabs>
        </div>
      </div>

      <div className="w-5/6 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 justify-items-center gap-8 mlb-8">
        {Object.entries(posts).map(([key, post], index: number) => {
          return (
            <PostCard
              key={post?.slug}
              post={post}
              showImage={index % 2 == 0 ? true : false}
            />
          );
        })}
      </div>

      <div className="w-full md:w-5/6 flex justify-end">
        <Pagination
          defaultCurrent={1}
          current={pagingInfo.pageNo}
          onChange={(pageNumber: number, pageSize: number) => {
            setFilterState({
              PageNumber: pageNumber,
              searchText: filterState?.searchText as string,
            });
          }}
          total={pagingInfo?.totalCount}
        />
      </div>
    </div>
  );
}
