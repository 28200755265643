import * as React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import * as Icons from "../../assets/icons.export";
// import * as Images from "../../assets/images.export";
import { RootState } from "../../data/reducers";
import * as shellPageActions from "data/reducers/shellPage.reducer";
import * as authActions from "data/reducers/auth.reducer";
import styles from "./ShellPage.module.css";

// import * as authActions from "data/reducers/auth/auth.reducer";
import Dropdown from "shared/components/Dropdown";

import { HomeFilled } from "@ant-design/icons";

type ShellPageProps = {
  selectedTab?: shellPageActions.ApplicationTabs;
  children: React.ReactElement;
  showTabs?: boolean;
};

const ShellPage = (props: ShellPageProps) => {
  const dispatch = useDispatch();
  const selectedTab = useSelector(
    (state: RootState) => state.shellReducer.selectedTab
  );

  const history = useHistory();

  const [navOpen, setNavOpen] = useState(false);
  const toggleNav = () => {
    const sideNav = document.getElementById("sideNav");
    if (sideNav) {
      if (!navOpen) {
        sideNav.style.width = "85%";
        sideNav.style.maxWidth = "390px";
        setNavOpen(true);
      } else {
        sideNav.style.width = "0px";
        setNavOpen(false);
      }
    }
  };

  const [tabs, setTabs] = useState([
    {
      name: "Home",
      value: shellPageActions.ApplicationTabs.Home,
      icon: <HomeFilled className="text-lg" />,
    },
    {
      name: "Events",
      value: shellPageActions.ApplicationTabs.Events,
      icon: (
        <img src={Icons.UI.Events} className="h-5 inline mr-3" alt="Events" />
      ),
    },
    {
      name: "Posts",
      value: shellPageActions.ApplicationTabs.Posts,
      icon: (
        <img src={Icons.UI.Posts} className="h-5 inline mr-3" alt="Posts" />
      ),
    },
    {
      name: "Communities",
      value: shellPageActions.ApplicationTabs.Communities,
      icon: (
        <img
          src={Icons.UI.Communities}
          className="h-5 inline mr-3"
          alt="Communities"
        />
      ),
    },
    {
      name: "allblogs",
      value: shellPageActions.ApplicationTabs.allblogs,
      icon: (
        <img
          src={Icons.UI.Posts}
          className="h-5 inline mr-3"
          alt="blogs"
        />
      ),
    },
  ]);
  const [tabElements, setTabElements]: any = useState([]);

  const setSelectedTab = () => {
    dispatch(shellPageActions.changeSelectedTab({ selectedTab: selectedTab }));
  };

  const setInitialTab = () => {
    const path = window.location.pathname.split("/")[1];
    switch (path) {
      case "home":
        dispatch(
          shellPageActions.changeSelectedTab({
            selectedTab: shellPageActions.ApplicationTabs.Home,
          })
        );
        return;
      case "events":
        dispatch(
          shellPageActions.changeSelectedTab({
            selectedTab: shellPageActions.ApplicationTabs.Events,
          })
        );
        return;
      case "posts":
        dispatch(
          shellPageActions.changeSelectedTab({
            selectedTab: shellPageActions.ApplicationTabs.Posts,
          })
        );
        return;
      case "communities":
        dispatch(
          shellPageActions.changeSelectedTab({
            selectedTab: shellPageActions.ApplicationTabs.Communities,
          })
        );
        return;
      default:
        dispatch(
          shellPageActions.changeSelectedTab({
            selectedTab: shellPageActions.ApplicationTabs.Home,
          })
        );
        return;
    }
  };

  const setTabsHtml = () => {
    let tabList: any = [];
    tabs.forEach((tab: any, index: Number) => {
      if (selectedTab == index) {
        tabList.push(
          <button
            onClick={() => onTabChange(tab)}
            className={
              tab.name !== "Home"
                ? "h-full w-36 px-2 cursor-pointer bg-secondaryDark rounded-none font-semibold border-b-4 border-yellow text-gray-300 " +
                  styles.tabRightBorder
                : "h-full w-16 px-2 cursor-pointer bg-secondaryDark rounded-none font-semibold border-b-4 border-yellow text-gray-300 " +
                  styles.tabRightBorder
            }
          >
            {tab.name !== "Home" && tab.icon}
            {tab.name !== "Home" ? tab.name : tab.icon}
          </button>
        );
      } else {
        tabList.push(
          <button
            onClick={() => onTabChange(tab)}
            className={
              tab.name !== "Home"
                ? "h-full w-36 px-2 cursor-pointer bg-dark hover:bg-secondaryDark hover:border-gray-500 rounded-none border-b-4 border-dark font-semibold hover:text-gray-400 text-white " +
                  styles.tabRightBorder
                : "h-full w-16 px-2 cursor-pointer bg-dark hover:bg-secondaryDark hover:border-gray-500 rounded-none border-b-4 border-dark font-semibold hover:text-gray-400 text-white " +
                  styles.tabRightBorder
            }
          >
            {tab.name !== "Home" && tab.icon}
            {tab.name !== "Home" ? tab.name : tab.icon}
          </button>
        );
      }
    });
    setTabElements([...tabList]);
  };

  const onTabChange = (tab: any) => {
    dispatch(shellPageActions.changeSelectedTab({ selectedTab: tab.value }));
    history.push(`/${tab.name.toLowerCase()}`);
  };

  React.useEffect(() => {
    setSelectedTab();
    setTabsHtml();
  }, [selectedTab]);

  React.useEffect(() => {
    setInitialTab();
  }, []);

  return (
    <div className="relative">
      <div className="h-20 flex lg:items-center lg:justify-center">
        <div className="lg:w-5/6 w-full h-full flex flex-row justify-between">
          <div
            onClick={() => {
              history.push("/");
            }}
            className="hidden lg:flex items-center gap-2 sm:gap-4 cursor-pointer"
          >
            <img
              className="w-2/5"
              src={Icons.Branding.AzureCommunitiesApac}
              alt="Communities Logo"
            />
            <div className={`h-2/3 ${styles.divider}`}></div>
            <img
              className="w-1/5"
              src={Icons.Branding.MsftFullGray}
              alt="MSFT Logo"
            />
            <div className={`h-2/3 ${styles.divider}`}></div>
            <div className="gap-2 flex flex-col">
              <span className="uppercase text-xs">powered by</span>
              <img
                className="w-2/3"
                src={Icons.Branding.StyavaDev}
                alt="Styava Dev Logo"
              />
            </div>
          </div>

          <div
            onClick={() => {
              history.push("/");
            }}
            className="flex lg:hidden items-center gap-2 sm:gap-4 cursor-pointer pli-2 pie-4"
          >
            <div className="flex flex-col -mie-4 gap-1">
              <img
                className="w-11/12 border-r border-gray-400 pie-4"
                src={Icons.Branding.AzureCommunitiesApac}
                alt="Communities Logo"
              />
              <div className="gap-2 flex flex-row">
                <span className="text-sm text-black opacity-60">
                  Powered by
                </span>
                <img
                  className="h-2.5 sm:h-3.5 mbs-1.5 sm:mbs-1"
                  src={Icons.Branding.StyavaDev}
                  alt="Styava Dev Logo"
                />
              </div>
            </div>
            <img
              className="w-2/6 sm:w-2/5 -mbs-6 sm:-mbs-5"
              src={Icons.Branding.MsftFullGray}
              alt="MSFT Logo"
            />
          </div>

          <div className="py-7 h-full">
            <button className="hidden lg:inline-block align-top mr-4 -mt-2">
              {/* <Dropdown
                title={"Language"}
                items={languageList}
                selectedItem={i18n.language}
              /> */}
            </button>
            <button
              onClick={toggleNav}
              className="mr-6 inline-block align-top mt-2"
            >
              <img src={Icons.UI.Hamburger} alt="" />
            </button>
          </div>

          <div id="sideNav" className="z-40 text-lg block">
            <button
              id="menu-close-btn"
              className="absolute right-7 top-7"
              onClick={toggleNav}
            >
              <img src={Icons.UI.MenuClose} alt="close_menu" />
            </button>

            <div className="block mx-8">
              <div className="flex flex-col">
                <button
                  className="w-full border border-white text-white py-2 my-4"
                  onClick={() => {
                    dispatch(authActions.signoutUser({}));
                  }}
                >
                  Logout
                </button>
                {/* <Dropdown
                  title={"Language"}
                  items={languageList}
                  selectedItem={i18n.language}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {props.showTabs === false ? (
        <></>
      ) : (
        <div className="h-16 w-full bg-dark">
          <nav className="h-16 w-full lg:w-5/6 mx-auto bg-dark">
            {tabElements.map((tab: any) => tab)}
          </nav>
        </div>
      )}

      {props.children}
    </div>
  );
};

export default ShellPage;
